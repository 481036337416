import React from 'react';
import '../App.css';
import '../style.css';
import { Onboarding } from './Onboarding'

export function MainContent() {
    return (
        <div>
            <Onboarding />
        </div>
    )
}