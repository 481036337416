import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import '../App.css';
import '../style.css';
import '../styles.css';
import '@coreui/coreui/dist/css/coreui.min.css';
import { format } from 'date-fns';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import { Popover } from 'react-tiny-popover'
// import { IMaskMixin } from 'react-imask'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { 
    CForm, 
    CFormInput, 
    CCol, 
    CContainer, 
    CRow, 
    CFormTextarea, 
    CButton, 
    CModal, 
    CModalHeader, 
    CModalTitle, 
    CModalBody, 
    CModalFooter, 
    CTable, 
    CTableHead, 
    CTableRow, 
    CTableHeaderCell, 
    CTableDataCell, 
    CTableBody, 
    CAccordionBody, 
    CAccordionHeader, 
    CAccordion, 
    CAccordionItem, 
    CCallout, 
    CFormCheck 
} from '@coreui/react';

const { REACT_APP_AUTH0_DOMAIN } = process.env;
const { REACT_APP_API_CLIENT_ID } = process.env;
const { REACT_APP_API_CLIENT } = process.env;
const { REACT_APP_API_HOST } = process.env;

const notify = (str) => toast.error(<span style={{fontSize: "17px"}}>{str}</span>, {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
});

// const CFormInputWithMask = IMaskMixin(({ inputRef, ...props }) => (
//     <CFormInput
//       {...props}
//       ref={inputRef} // bind internal input
//     />
//   ))

// const getMetadata = async () => {
//     const { getUser } = useAuth0();
//     const userRes = await getUser();
//     const appMetadata = userRes.app_metadata;
//     console.log(appMetadata)
// }

export function OffboardingForm () {
    document.title = "Offboarding - JSI ECM"

    const { user, logout } = useAuth0();

    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [cutoffDate, setCutoffDate] = useState();
    const [cutoffTime, setCutoffTime] = useState();
    const [comment, setComment] = useState();

    const [datePickerVisible, setDatePickerVisible] = useState();
    const [editDatePickerVisible, setEditDatePickerVisible] = useState();
    const [visible, setVisible] = useState(false)

    const [firstNameEdit, setFirstNameEdit] = useState();
    const [lastNameEdit, setLastNameEdit] = useState();
    const [commentEdit, setCommentEdit] = useState();
    const [cutoffDateEdit, setCutoffDateEdit] = useState();
    const [cutoffTimeEdit, setCutoffTimeEdit] = useState();

    const [forwardChecked, setForwardChecked] = useState();
    const [forwardDisabled, setForwardDisabled] = useState();
    const [sharedMailboxChecked, setSharedMailboxChecked] = useState();
    const [sharedMailboxDisabled, setSharedMailboxDisabled] = useState();
    const [ediscoveryChecked, setEdiscoveryChecked] = useState();
    const [ediscoveryInquireChecked, setEdiscoveryInquireChecked] = useState();
    const [forwardRecips, setForwardRecips] = useState();
    const [sharedMailboxDelegates, setSharedMailboxDelegates] = useState();

    const [submittingForm, setSubmittingForm] = useState(false);
    const [success, setSuccess] = useState();
    const [submitError, setSubmitError] = useState(false);
    const [submittedSuccess, setSubmittedSuccess] = useState(false);
    const [resultTicket, setResultTicket] = useState();

    let footer = <p>Choose a cutoff date</p>;
    if (cutoffDate) {
      footer = <p>{(firstName ? `${firstName}'s accounts will be disabled on ${format(cutoffDate, 'PPP')}` : `Cutoff on ${format(cutoffDate, 'PPP')}`)}</p>;
    }

    const getTimeNow = (timeString) => {
        if (timeString) {
            let hour = parseInt(timeString.substring(0,2));
            let minutes = timeString.substring(3);
            
            if (hour > 12) {
              hour -= 12;
              return `${hour}:${minutes} PM`;
            } else if (hour === 0) {
              hour += 12;
              return `${hour}:${minutes} AM`;
            } else if (hour === 12) {
              return `${hour}:${minutes} PM`;
            } else {
              return `${hour}:${minutes} AM`;
            }
        }
    }

    const checkRequiredFields = () => {
        if (firstName === "" || firstName === undefined) {
            notify("First name is required")
            setVisible(false)
            document.getElementById("onboarding-div").scrollIntoView()
            document.getElementById("fNameInput").focus()
            return false;
        } else if (lastName === "" || lastName === undefined) {
            notify("Last name is required")
            setVisible(false)
            document.getElementById("onboarding-div").scrollIntoView()
            document.getElementById("lNameInput").focus()
            return false;
        } else if (cutoffDate === "" || cutoffDate === undefined) {
            notify("Cutoff date is required")
            setVisible(false)
            document.getElementById("onboarding-div").scrollIntoView()
            document.getElementById("cutoffDateInput").focus()
            return false;
        } else if (cutoffTime === "" | cutoffTime === undefined) {
            notify("Cutoff time is required")
            document.getElementById("onboarding-div").scrollIntoView()
            document.getElementById("cutoffTimeInput").focus()
            setVisible(false)
            return false;
        } else {
            setVisible(!visible)
            return true;
        }
      }

    const submitForm = () => {
        setSubmittingForm(true)
    }

    const handleFormSubmit = (e) => {
        e.preventDefault()
        submitForm()
        setSubmittingForm(true)
        setSubmitError(false)
        if (
            firstName !== "" && 
            firstName !== undefined && 
            lastName !== "" && 
            lastName !== undefined && 
            cutoffDate !== "" && 
            cutoffDate !== undefined && 
            cutoffTime !== "" && 
            cutoffTime !== undefined
          ) {
        try {
          async function getData(accessToken) {
            setSubmittingForm(true)
            setSubmitError(false)
            const response = await fetch(REACT_APP_API_HOST + '/api/v1/submitoffb', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${accessToken}`
              },
              body: JSON.stringify({
                requestType: `OFFBOARDING`,
                submittedByName: `${user.name}`,
                submittedByEmail: `${user.email}`,
                firstName: `${firstName}`,
                lastName: `${lastName}`,
                cutoffDate: `${format(cutoffDate, 'PPP')}`,
                cutoffTime: `${getTimeNow(cutoffTime)}`,
                ediscoveryChecked: `${ediscoveryChecked === undefined ? 'False' : ediscoveryChecked}`,
                ediscoveryInquireChecked: `${ediscoveryInquireChecked === undefined ? 'False' : ediscoveryInquireChecked}`,
                forwardChecked: `${forwardChecked === undefined ? 'False' : forwardChecked}`,
                forwardRecips: `${forwardRecips === undefined ? '*BLANK*' : forwardRecips}`,
                sharedMailboxChecked: `${sharedMailboxChecked === undefined ? 'False' : sharedMailboxChecked}`,
                sharedMailboxDelegates: `${sharedMailboxDelegates === undefined ? '*BLANK*' : sharedMailboxDelegates}`,
                comment: `${comment === undefined ? '*BLANK*' : comment}`
              })
            });
            const data = await response.json();

            data.ticketId && setResultTicket(data.ticketId)
            data.ticketId ? setSuccess(true) : setSuccess(false)
            data.ticketId ? setSubmittedSuccess(true) : setSubmittedSuccess(false)
            
            setSubmittingForm(false)
          }
          async function getToken() {
            setSubmittingForm(true)
            setSubmitError(false)
            try {
              setSubmittingForm(true)
              const response = await fetch(`https://${REACT_APP_AUTH0_DOMAIN}/oauth/token`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  client_id: REACT_APP_API_CLIENT_ID,
                  client_secret: REACT_APP_API_CLIENT,
                  audience: 'ecm-backend',
                  grant_type: 'client_credentials' 
                }) 
              });
              const data = await response.json();
              getData(data.access_token)
            } catch (error) {
              console.log(error); 
              setSubmitError(true);
            }  
          }
          getToken()
        } catch {
            setSubmitError(true)
        }
        } else {
            return (
                ''
            )
        }
        setSubmittingForm(false)
    }

    const hoverShowSidebar = (title, desc) => {
        document.getElementById("sidebar-title").innerHTML = `${title}`
        document.getElementById("sidebar-desc").innerHTML = `${desc}`
    }
    function hoverOutSidebar () {
        document.getElementById("sidebar-title").innerHTML = ""
        document.getElementById("sidebar-desc").innerHTML = ""
    }

    return (
        <div style={{marginTop: "-20px"}}>
            <CForm onSubmit={handleFormSubmit} autoComplete="off">
            <CContainer>
                <div style={{paddingTop: "15px"}}>
                    <CCallout style={{color: "#EB1B2C", fontSize: "18px"}} color="danger">
                        If this is an urgent or risky termination, please call us at <a className='banner-link' href='tel:+15853219999'><b>585-321-9999</b></a> for the fastest response time.
                    </CCallout>
                </div>
                <CAccordion activeItemKey={0}>
                    <CAccordionItem itemKey={0}>
                        <CAccordionHeader>Employee Name</CAccordionHeader>
                            <CAccordionBody>
                                <CRow className='accordian-row' style={{width: "100%"}}>
                                    <CCol sm={6}>
                                        <CFormInput
                                        autoFocus
                                        type="text"
                                        id="fNameInput"
                                        label="First name"
                                        placeholder="First"
                                        aria-describedby="exampleFormControlInputHelpInline"
                                        value={firstName}
                                        onChange={(e) => {
                                            setFirstName(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1));
                                        }}
                                        required
                                        onFocus={() => (hoverShowSidebar('Employee Name', 'Enter the first and last name of the terminated employee.'))}
                                        onBlur={() => hoverOutSidebar()}
                                        />
                                    </CCol>
                                    <CCol sm={6}>
                                        <CFormInput
                                        type="text"
                                        id="lNameInput"
                                        label="Last name"
                                        value={lastName}
                                        placeholder="Last"
                                        aria-describedby="exampleFormControlInputHelpInline"
                                        onChange={(e) => {
                                            setLastName(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1));
                                        }}
                                        required
                                        onFocus={() => (hoverShowSidebar('Employee Name', 'Enter the first and last name of the terminated employee.'))}
                                        onBlur={() => hoverOutSidebar()}
                                        />
                                    </CCol>
                                </CRow>
                            </CAccordionBody>
                        </CAccordionItem>
                        <CAccordionItem itemKey={1}>
                            <CAccordionHeader>Account Cutoff Date & Time</CAccordionHeader>
                            <CAccordionBody>
                                <CRow style={{width: "100%"}}>
                                    <CCol sm={6}>
                                    <Popover
                                        positions={['top', 'right', 'left', 'bottom']}
                                        content={
                                            <DayPicker
                                            mode="single"
                                            selectedDate={cutoffDate}
                                            onSelect={setCutoffDate}
                                            footer={footer}
                                            showOutsideDays
                                            fixedWeeks
                                            />
                                        }
                                        isOpen={datePickerVisible}
                                        onClickOutside={() => setDatePickerVisible(false)}
                                        padding={15}
                                        >
                                            <CFormInput
                                                onClick={() => setDatePickerVisible(true)}
                                                type="text"
                                                id="cutoffDateInput"
                                                label="Cutoff Date"
                                                placeholder="Pick a date"
                                                aria-describedby="exampleFormControlInputHelpInline"
                                                value={(cutoffDate && format(cutoffDate, 'PPP'))}
                                                onChange={(e) => {
                                                    setCutoffDate(e.target.value);
                                                }}
                                                required
                                                onFocus={() => (setDatePickerVisible(true) & hoverShowSidebar('Cutoff Date', `Enter ${firstName ? firstName : 'your terminated employee'}'s cutoff date so we can ensure accounts are disabled in coordination with termination notice.`))}
                                                onBlur={() => (hoverOutSidebar())}
                                                
                                            />
                                        </Popover>
                                    </CCol>
                                    <CCol sm={6}>
                                        <div style={{paddingBottom: "8px"}}>
                                            <span id="cutoffTimeLabel" style={{color: "#5a5a5a"}}>Cutoff Time</span>
                                        </div>
                                        <TimePicker id='cutoffTimeInput' onChange={setCutoffTime} value={cutoffTime} />
                                    </CCol>
                                </CRow>
                            </CAccordionBody>
                        </CAccordionItem>
                        <CAccordionItem itemKey={2}>
                            <CAccordionHeader>Email / 365 Account</CAccordionHeader>
                            <CAccordionBody>
                                <div style={{paddingBottom: "20px"}}>
                                    <span className='heading' style={{color: "white", fontSize: "22px"}}>If {firstName ? firstName : 'your terminated employee'} has an email/365 account, what should we do with it?</span>
                                    <br/>
                                    <span className='subheading' style={{color: "grey", fontSize: "15px"}}>NOTE: We can only take action on email accounts for clients with active managed email support agreements.</span>
                                </div>
                                <CRow style={{width: "100%"}}>
                                    <CCol sm={6}>
                                        <CFormCheck id="reset-password-cb" label="Force sign-out and reset password" defaultChecked disabled/>
                                        <CFormCheck 
                                            id="ediscovery-cb" 
                                            label="Hold for eDiscovery (only where applicably licensed)"
                                            checked={ediscoveryChecked}
                                            onChange={(e) => {
                                                setEdiscoveryChecked(ediscoveryChecked ? false : true)
                                            }}
                                        />
                                        {ediscoveryChecked ? (
                                            <div style={{paddingLeft: "15px"}}>
                                                <CFormCheck 
                                                    onChange={(e) => {
                                                        setEdiscoveryInquireChecked(ediscoveryInquireChecked ? false : true)
                                                    }} 
                                                    id="ediscovery-inquire-cb" 
                                                    checked={ediscoveryInquireChecked} 
                                                    label="If not licensed, have my account manager reach out to me to discuss licensing."/>
                                            </div>
                                        ) : ''}
                                    </CCol>
                                    <CCol sm={6}>
                                        <div 
                                            onMouseOver={() => (forwardChecked !== true && hoverShowSidebar('Forward Email', `We can forward ${firstName ? firstName : 'your terminated employee'}'s email to other recipients if needed. Please note that email forwarding will require an active license. Converting to a shared mailbox does not require a license.`))}
                                            onMouseOut={() => forwardChecked !== true && hoverOutSidebar()}
                                        >
                                            <CFormCheck 
                                                id="forward-email-cb" 
                                                label="Forward email to other recipients" 
                                                checked={forwardChecked}
                                                disabled={forwardDisabled}
                                                onChange={(e) => {
                                                    setForwardChecked(forwardChecked ? false : true)
                                                    sharedMailboxChecked && setSharedMailboxChecked(false)
                                                    e.target.checked ? setSharedMailboxDisabled(true) : setSharedMailboxDisabled(false)
                                                }}
                                                onMouseOver={() => (forwardChecked !== true && hoverShowSidebar('Forward Email', `We can forward ${firstName ? firstName : 'your terminated employee'}'s email to other recipients if needed. Please note that email forwarding will require an active license. Converting to a shared mailbox does not require a license.`))}
                                                onMouseOut={() => forwardChecked !== true && hoverOutSidebar()}
                                            />
                                        </div>
                                        {forwardChecked ? (
                                            <div style={{paddingBottom: "15px"}}>
                                            <CFormInput
                                                autoFocus
                                                type="text"
                                                id="exampleFormCon"
                                                label="Specify Recipients"
                                                value={forwardRecips}
                                                placeholder="First and last name or email address"
                                                aria-describedby="exampleFormControlInputHelpInline"
                                                onChange={(e) => {
                                                    setForwardRecips(e.target.value);
                                                }}
                                                required
                                                onFocus={() => (hoverShowSidebar('Recipients', `Enter the first and last name or email address of those we should forward ${firstName ? firstName : 'your terminated employee'}'s new emails to. Separate multiple recipients with commas.`))}
                                                onBlur={() => hoverOutSidebar()}
                                            />
                                            </div>
                                        ) : ''}

                                        <div 
                                            onMouseOver={() => (sharedMailboxChecked !== true && hoverShowSidebar('Convert to shared mailbox', `We can convert ${firstName ? firstName : 'your terminated employee'}'s mailbox to a shared mailbox and delegate others to monitor it. Please note that email forwarding will require an active license. Converting to a shared mailbox does not require a license.`))}
                                            onMouseOut={() => sharedMailboxChecked !== true && hoverOutSidebar()}
                                        >
                                            <CFormCheck 
                                                id="shared-mailbox-cb" 
                                                label="Convert to shared mailbox and delegate" 
                                                checked={sharedMailboxChecked}
                                                disabled={sharedMailboxDisabled}
                                                onChange={(e) => {
                                                    setSharedMailboxChecked(sharedMailboxChecked ? false : true)
                                                    forwardChecked && setForwardChecked(false)
                                                    e.target.checked ? setForwardDisabled(true) : setForwardDisabled(false)
                                                }}
                                                onMouseOver={() => (sharedMailboxChecked !== true && hoverShowSidebar('Convert to shared mailbox', `We can convert ${firstName ? firstName : 'your terminated employee'}'s mailbox to a shared mailbox and delegate others to monitor it. Please note that email forwarding will require an active license. Converting to a shared mailbox does not require a license.`))}
                                                onMouseOut={() => sharedMailboxChecked !== true && hoverOutSidebar()}
                                            />
                                        </div>
                                        {sharedMailboxChecked ? (
                                            <CFormInput
                                                autoFocus
                                                type="text"
                                                id="exampleFormControlInput1"
                                                label="Specify Delegates"
                                                value={sharedMailboxDelegates}
                                                placeholder="First and last name or email address"
                                                aria-describedby="exampleFormControlInputHelpInline"
                                                onChange={(e) => {
                                                    setSharedMailboxDelegates(e.target.value);
                                                }}
                                                required
                                                onFocus={() => (hoverShowSidebar('Delegates', `Enter the first and last name or email address of those we should delegate ${firstName ? firstName : 'your terminated employee'}'s mailbox to. Separate multiple delegates with commas.`))}
                                                onBlur={() => hoverOutSidebar()}
                                            />
                                        ) : ''}
                                    </CCol>
                                </CRow>
                                </CAccordionBody>
                        </CAccordionItem>
                </CAccordion>
                <CRow style={{width: "100%"}}>
                    <CCol sm={12}>
                        <CFormTextarea
                        id="exampleFormControlTextarea1"
                        label="Other comments or specific needs"
                        rows={3}
                        value={comment}
                        text="Any additional permission revokation requirements, accounts to disable, or licenses to remove."
                        onChange={(e) => {
                            setComment(e.target.value);
                        }}
                        onFocus={() => (hoverShowSidebar('Additional Information',`Any additional permission revokation requirements, accounts to disable, licenses to remove, software to disable or general comments, concerns or needs.`))}
                        onBlur={() => hoverOutSidebar()}
                        ></CFormTextarea>
                    </CCol>
                </CRow>
            <CRow style={{width: "100%"}}>
                <CCol sm={12}>
                    <>
                      <CButton onClick={() => checkRequiredFields()}>Review & Submit</CButton>
                      <CModal scrollable alignment='center' visible={visible} onClose={() => setVisible(false)}>
                        {submittedSuccess ?
                        <>
                            <CModalHeader onClose={() => setVisible(false)}>
                              <CModalTitle style={{fontSize: "16px"}}>Success</CModalTitle>
                            </CModalHeader>
                            <div class="success-animation">
                                <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" /><path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
                                <br/>
                                <div style={{textAlign: "center"}}>
                                    <h2 className='submitted-ticket-msg'>Ticket {resultTicket} has been submitted.</h2>
                                </div>
                                <div style={{textAlign: "center"}}>
                                    <span style={{fontSize: "20px"}}>You'll receive a confirmation email shortly!</span>
                                </div>
                            </div>
                        </>
                        :
                        <>
                        <CModalHeader onClose={() => setVisible(false)}>
                            <CModalTitle style={{fontSize: "20px"}}>Review</CModalTitle>
                        </CModalHeader>
                        <CModalBody>
                        <CTable>
                            <CTableHead>
                                <CTableRow>
                                    <CTableHeaderCell scope="col">Data</CTableHeaderCell>
                                    <CTableHeaderCell scope="col">Value</CTableHeaderCell>
                                </CTableRow>
                            </CTableHead>
                            <CTableBody>
                                <CTableRow>
                                    <CTableHeaderCell scope="row">First Name</CTableHeaderCell>
                                    <CTableDataCell>{firstNameEdit ? 
                                        <span>
                                            <CFormInput autoFocus className='modalForm' value={firstName} onChange={(e) => { 
                                                setFirstName(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)) 
                                            }} onBlur={() => (setFirstNameEdit(false))} /> <i onClick={() => setFirstNameEdit(false)} style={{paddingLeft: "7px", fontSize: "18px", cursor: "pointer"}} className="fa-solid fa-check"></i>
                                        </span>
                                        : 
                                        <span>
                                            {firstName} <i onClick={() => setFirstNameEdit(true)} style={{paddingLeft: "7px", cursor: "pointer"}} className="fa-solid fa-pencil"></i>
                                        </span>
                                    }</CTableDataCell>
                                </CTableRow>
                                <CTableRow>
                                    <CTableHeaderCell scope="row">Last Name</CTableHeaderCell>
                                    <CTableDataCell>{lastNameEdit ? 
                                        <span>
                                            <CFormInput autoFocus className='modalForm' value={lastName} onChange={(e) => { setLastName(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)) }} onBlur={() => (setLastNameEdit(false))} /> <i onClick={() => setLastNameEdit(false)} style={{paddingLeft: "7px", fontSize: "18px", cursor: "pointer"}} className="fa-solid fa-check"></i>
                                        </span>
                                        : 
                                        <span>
                                            {lastName} <i onClick={() => setLastNameEdit(true)} style={{paddingLeft: "7px", cursor: "pointer"}} className="fa-solid fa-pencil"></i>
                                        </span>
                                    }</CTableDataCell>
                                </CTableRow>
                                <CTableRow>
                                    <CTableHeaderCell scope="row">Cutoff Date</CTableHeaderCell>
                                    <CTableDataCell>{cutoffDateEdit ? 
                                            <CContainer fluid>
                                            <Popover
                                              positions={['top', 'right', 'left', 'bottom']}
                                              content={
                                                <DayPicker
                                                  mode="single"
                                                  selectedDate={cutoffDate}
                                                  onSelect={setCutoffDate}
                                                  footer={footer}
                                                  showOutsideDays
                                                  fixedWeeks
                                                  style={{zIndex: "999999999999"}}
                                                />
                                              }
                                              isOpen={editDatePickerVisible}
                                              onClickOutside={() => setEditDatePickerVisible(false)}
                                              padding={15}
                                              style={{zIndex: "999999999999"}}
                                              onBlur={() => (setCutoffDateEdit(false))}
                                            >
                                                <CFormInput
                                                    autoFocus
                                                    className='modalForm'
                                                    onClick={() => setEditDatePickerVisible(true)}
                                                    value={(cutoffDate && format(cutoffDate, 'PPP'))}
                                                    onChange={(e) => {
                                                        setCutoffDate(e.target.value);
                                                    }}
                                                    onFocus={() => (setEditDatePickerVisible(true))}
                                                    onBlur={() => (setCutoffDateEdit(false))}
                                                />
                                            
                                            
                                            </Popover> <i onClick={() => setCutoffDateEdit(false)} style={{paddingLeft: "7px", fontSize: "18px", cursor: "pointer"}} className="fa-solid fa-check"></i>
                                        </CContainer>
                                        : 
                                        <span>
                                            {cutoffDate && format(cutoffDate, 'PPP')} <i onClick={() => setCutoffDateEdit(true)} style={{paddingLeft: "7px", cursor: "pointer"}} className="fa-solid fa-pencil"></i>
                                        </span>
                                    }</CTableDataCell>
                                </CTableRow>
                                <CTableRow>
                                    <CTableHeaderCell scope="row">Cutoff Time</CTableHeaderCell>
                                    <CTableDataCell>{cutoffTimeEdit ? 
                                        <span onBlur={() => (setCutoffTimeEdit(false))}>
                                            <TimePicker onChange={setCutoffTime} value={cutoffTime} />
                                        </span>
                                        : 
                                        <span>
                                            {getTimeNow(cutoffTime)} <i onClick={() => setCutoffTimeEdit(true)} style={{paddingLeft: "7px", cursor: "pointer"}} className="fa-solid fa-pencil"></i>
                                        </span>
                                    }</CTableDataCell>
                                </CTableRow>
                                <CTableRow>
                                    <CTableHeaderCell scope="row">eDiscovery/Legal Hold</CTableHeaderCell>
                                    <CTableDataCell>
                                        <span style={{paddingLeft: "20px"}}>
                                            <CFormCheck 
                                                id="ediscovery-cb"
                                                label=""
                                                checked={ediscoveryChecked}
                                                onChange={(e) => {
                                                    setEdiscoveryChecked(ediscoveryChecked ? false : true)
                                                }}
                                            />
                                        </span>
                                    </CTableDataCell>
                                </CTableRow>
                                <CTableRow>
                                    <CTableHeaderCell scope="row">eDiscovery Inquiry</CTableHeaderCell>
                                    <CTableDataCell>
                                        <span style={{paddingLeft: "20px"}}>
                                            <CFormCheck 
                                                id="ediscovery-inquire-cb"
                                                label=""
                                                checked={ediscoveryInquireChecked}
                                                onChange={(e) => {
                                                    setEdiscoveryInquireChecked(ediscoveryInquireChecked ? false : true)
                                                }}
                                            />
                                        </span>
                                    </CTableDataCell>
                                </CTableRow>
                                <CTableRow>
                                    <CTableHeaderCell scope="row">Forward Email</CTableHeaderCell>
                                    <CTableDataCell>
                                    <div 
                                        style={{paddingLeft: "20px"}}
                                    >
                                        <CFormCheck 
                                            id="forward-email-cb" 
                                            checked={forwardChecked}
                                            disabled={forwardDisabled}
                                            onChange={(e) => {
                                                setForwardChecked(forwardChecked ? false : true)
                                                sharedMailboxChecked && setSharedMailboxChecked(false)
                                                e.target.checked ? setSharedMailboxDisabled(true) : setSharedMailboxDisabled(false)
                                            }}
                                            onMouseOver={() => (forwardChecked !== true && hoverShowSidebar('Forward Email', `We can forward ${firstName ? firstName : 'your terminated employee'}'s email to other recipients if needed. Please note that email forwarding will require an active license. Converting to a shared mailbox does not require a license.`))}
                                            onMouseOut={() => forwardChecked !== true && hoverOutSidebar()}
                                        />
                                    </div>
                                    </CTableDataCell>
                                </CTableRow>

                                {forwardChecked ? (
                                <CTableRow>
                                    <CTableHeaderCell scope="row">Forward Recipients</CTableHeaderCell>
                                    <CTableDataCell>
                                        <CFormInput
                                            autoFocus
                                            className='modalForm'
                                            type="text"
                                            id="exampleFormCon"
                                            value={forwardRecips}
                                            placeholder="First and last name or email address"
                                            aria-describedby="exampleFormControlInputHelpInline"
                                            onChange={(e) => {
                                                setForwardRecips(e.target.value);
                                            }}
                                            required
                                        />
                                    </CTableDataCell>
                                </CTableRow>
                                    ) : ''}

                                <CTableRow>
                                    <CTableHeaderCell scope="row">Convert to shared mailbox</CTableHeaderCell>
                                    <CTableDataCell>
                                    <div 
                                        style={{paddingLeft: "20px"}}
                                    >
                                        <CFormCheck 
                                            id="shared-mailbox-cb" 
                                            checked={sharedMailboxChecked}
                                            disabled={sharedMailboxDisabled}
                                            onChange={(e) => {
                                                setSharedMailboxChecked(sharedMailboxChecked ? false : true)
                                                forwardChecked && setForwardChecked(false)
                                                e.target.checked ? setForwardDisabled(true) : setForwardDisabled(false)
                                            }}
                                            onMouseOver={() => (sharedMailboxChecked !== true && hoverShowSidebar('Convert to shared mailbox', `We can convert ${firstName ? firstName : 'your terminated employee'}'s mailbox to a shared mailbox and delegate others to monitor it. Please note that email forwarding will require an active license. Converting to a shared mailbox does not require a license.`))}
                                            onMouseOut={() => sharedMailboxChecked !== true && hoverOutSidebar()}
                                        />
                                    </div>
                                    </CTableDataCell>
                                </CTableRow>
                                {sharedMailboxChecked ? (
                                <CTableRow>
                                    <CTableHeaderCell scope="row">Shared Mailbox Delegates</CTableHeaderCell>
                                    <CTableDataCell>
                                        <CFormInput
                                            autoFocus
                                            type="text"
                                            className='modalForm'
                                            id="exampleFormControlInput1"
                                            value={sharedMailboxDelegates}
                                            placeholder="First and last name or email address"
                                            aria-describedby="exampleFormControlInputHelpInline"
                                            onChange={(e) => {
                                                setSharedMailboxDelegates(e.target.value);
                                            }}
                                            required
                                        />
                                    </CTableDataCell>
                                </CTableRow>
                                ) : ''}
                                <CTableRow>
                                    <CTableHeaderCell scope="row">Comment</CTableHeaderCell>
                                    <CTableDataCell>{commentEdit ? 
                                        <span>
                                            <CFormTextarea autoFocus rows={3} className='modalForm' value={comment} onChange={(e) => { setComment(e.target.value) }} onBlur={() => (setCommentEdit(false))} /> <i onClick={() => setCommentEdit(false)} style={{paddingLeft: "7px", fontSize: "18px", cursor: "pointer"}} className="fa-solid fa-check"></i>
                                        </span>
                                        : 
                                        <span>
                                            {comment} <i onClick={() => setCommentEdit(true)} style={{paddingLeft: "7px", cursor: "pointer"}} className="fa-solid fa-pencil"></i>
                                        </span>
                                    }</CTableDataCell>
                                </CTableRow>
                            </CTableBody>
                            </CTable>
                        </CModalBody>
                        </>
                        }
                        <CModalFooter>
                        {success ?
                          <CButton color="secondary" onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
                            Logout
                          </CButton>
                          :
                          <CButton color="secondary" onClick={() => setVisible(false)}>
                            Go Back
                          </CButton>
                          }

                          {submittingForm ? <CButton><span style={{paddingRight: "7px"}}><span className="btn-loader"></span></span> Submitting</CButton> : 
                            success ? <><CButton color='secondary' onClick={() => window.location.reload()}>Refresh Page</CButton><CButton disabled><i style={{paddingRight: "7px"}} className="fa fa-solid fa-check"></i> Submitted</CButton></>
                            : submitError ? <CButton onClick={() => window.location.reload()}>Refresh Page</CButton> : <CButton onClick={handleFormSubmit}>Submit</CButton>}
                        </CModalFooter>
                      </CModal>
                    </>
                </CCol>
            </CRow>
            </CContainer>
            </CForm>
            <ToastContainer />
        </div>
    )
}