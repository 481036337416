import React, { useState, useRef, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import '../App.css';
import '../style.css';
import '../styles.css';
import 'react-select-search/style.css'
import '@coreui/coreui/dist/css/coreui.min.css';
import { format } from 'date-fns';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { useLocalStorage } from './useLocalStorage';
import { Popover } from 'react-tiny-popover'
import { IMaskMixin } from 'react-imask'
import { WithContext as ReactTags } from 'react-tag-input';
import APPLICATIONS from '../APPLICATIONS.json';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DOMPurify from 'dompurify';
import { 
  CForm, 
  CFormInput, 
  CCol, 
  CContainer, 
  CRow, 
  CFormSelect, 
  CFormTextarea, 
  CButton, 
  CModal, 
  CModalHeader, 
  CModalTitle, 
  CModalBody, 
  CModalFooter, 
  CTable, 
  CTableHead, 
  CTableRow, 
  CTableHeaderCell, 
  CTableDataCell, 
  CTableBody, 
  CAccordionBody, 
  CAccordionHeader, 
  CAccordion, 
  CAccordionItem, 
  CListGroup, 
  CListGroupItem, 
  CTooltip, 
  CToaster
} from '@coreui/react';

const notify = (str) => toast.error(<span style={{fontSize: "17px"}}>{str}</span>, {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  closeButton: false,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
});

const { REACT_APP_AUTH0_DOMAIN } = process.env;
const { REACT_APP_API_CLIENT_ID } = process.env;
const { REACT_APP_API_CLIENT } = process.env;
const { REACT_APP_API_HOST } = process.env;

const suggestions = APPLICATIONS.map(app => {
    return {
      id: app.id,
      text: app.text
    };
  });

const CFormInputWithMask = IMaskMixin(({ inputRef, ...props }) => (
    <CFormInput
      {...props}
      ref={inputRef}
    />
  ))


export function OnboardingForm () {
    document.title = "Onboarding - JSI ECM"

    const { user, logout } = useAuth0();

    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [email, setEmail] = useState();
    const [license, setLicense] = useState();
    const [manager, setManager] = useState();
    const [machineName, setMachineName] = useState();
    const [username, setUsername] = useState();
    const [phone, setPhone] = useState();
    const [comment, setComment] = useState();
    const [userPermissions, setUserPermissions] = useState();
    const [userPermissionsExceptFor, setUserPermissionsExceptFor] = useState();
    const [permissionsComment, setPermissionsComment] = useState();
    const [permissionsCommentEdit, setPermissionsCommentEdit] = useState();
    const [permissionsMirrorEdit, setPermissionsMirrorEdit] = useState();
    const [permissionsExceptEdit, setPermissionsExceptEdit] = useState();

    const [datePickerVisible, setDatePickerVisible] = useState();
    const [editDatePickerVisible, setEditDatePickerVisible] = useState();
    const [visible, setVisible] = useState(false)

    const [firstNameEdit, setFirstNameEdit] = useState();
    const [lastNameEdit, setLastNameEdit] = useState();
    const [emailEdit, setEmailEdit] = useState();
    const [licenseEdit, setLicenseEdit] = useState();
    const [phoneEdit, setPhoneEdit] = useState();
    const [managerEdit, setManagerEdit] = useState();
    const [machineNameEdit, setMachineNameEdit] = useState();
    const [usernameEdit, setUsernameEdit] = useState();
    const [commentEdit, setCommentEdit] = useState();
    const [startDateEdit, setStartDateEdit] = useState();

    const [defaultEmail, setDefaultEmail] = useState();

    const [submittingForm, setSubmittingForm] = useState(false);
    const [success, setSuccess] = useState();
    const [submitError, setSubmitError] = useState(false);
    const [submittedSuccess, setSubmittedSuccess] = useState(false);
    const [resultTicket, setResultTicket] = useState();
    const [fontSizeLabel] = useLocalStorage("font-size-label");
    const [fontSizeContainer] = useLocalStorage("font-size-container");

    useEffect(() => {
      console.log(fontSizeContainer + ' onboardingform');
      var r = document.querySelector(':root');
      r.style.setProperty('--font-size-container', `${fontSizeContainer}px`);
    },[fontSizeContainer])

    useEffect(() => {
        function setFontLabels() {
            var l = document.querySelector(':root');
            l.style.setProperty('--font-size-labels', `${fontSizeLabel}px`);
        }
        setFontLabels()
    }, [fontSizeLabel])

    const [selectedDate, setSelectedDate] = useState();
    let footer = <p>Choose a start date</p>;
    if (selectedDate) {
      footer = <p>{(firstName ? `${firstName} will start on ${format(selectedDate, 'PPP')}` : `Starting on ${format(selectedDate, 'PPP')}`)}</p>;
    }

    const submitForm = () => {
      setSubmittingForm(true)
    }

    const checkRequiredFields = () => {
      if (firstName === "" || firstName === undefined) {
        notify("First name is required")
        setVisible(false)
        document.getElementById("onboarding-div").scrollIntoView()
        document.getElementById("fNameInput").focus()
        return false;
      } else if (lastName === "" || lastName === undefined) {
        notify("Last name is required")
        setVisible(false)
        document.getElementById("onboarding-div").scrollIntoView()
        document.getElementById("lNameInput").focus()
        return false;
      } else if (selectedDate === "" || selectedDate === undefined) {
        notify("Start date is required")
        setVisible(false)
        document.getElementById("onboarding-div").scrollIntoView()
        document.getElementById("startDateInput").focus()
        return false;
      } else {
        setVisible(!visible)
        return true;
      }
    }

    const handleFormSubmit = (e) => {
        e.preventDefault()
        submitForm()
        setSubmittingForm(true)
        setSubmitError(false)
        let arr = tags.map(value => value.text)
        if (firstName !== "" && lastName !== "" && selectedDate !== "") {
          try {
            async function getData(accessToken) {
              setSubmittingForm(true)
              const response = await fetch(REACT_APP_API_HOST + '/api/v1/submitonb', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  authorization: `Bearer ${accessToken}`
                },
                body: JSON.stringify({
                  requestType: `Onboarding`,
                  submittedByName: `${user.name}`,
                  submittedByEmail: `${user.email}`,
                  firstName: `${firstName}`,
                  lastName: `${lastName}`,
                  email: `${email}`,
                  license: `${license === undefined ? '*BLANK*' : license}`,
                  startDate: `${format(selectedDate, 'PPP')}`,
                  phone: `${phone === undefined ? '*BLANK*' : phone}`,
                  manager: `${manager === undefined ? '*BLANK*' : manager}`,
                  machineName: `${machineName === undefined ? '*BLANK*' : machineName}`,
                  applications: `${JSON.stringify(arr)}`,
                  userPermissions: `${userPermissions === undefined ? '*BLANK*' : userPermissions}`,
                  userPermissionsExceptFor: `${userPermissionsExceptFor === undefined ? '*BLANK*' : userPermissionsExceptFor}`,
                  permissionsComment: `${permissionsComment === undefined ? '*BLANK*' : permissionsComment}`,
                  comment: `${comment === undefined ? '*BLANK*' : comment}`
                })
              });
              try {
                const data = await response.json();

                data.ticketId && setResultTicket(data.ticketId)
                data.ticketId ? setSuccess(true) : setSuccess(false)
                data.ticketId ? setSubmittedSuccess(true) : setSubmittedSuccess(false)
                
                setSubmittingForm(false)
              } catch {
                setSubmittingForm(false)
                setSubmitError(true)
              }
            }
            async function getToken() {
              setSubmittingForm(true)
              try {
                setSubmittingForm(true)
                const response = await fetch(`https://${REACT_APP_AUTH0_DOMAIN}/oauth/token`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({
                    client_id: REACT_APP_API_CLIENT_ID,
                    client_secret: REACT_APP_API_CLIENT,
                    audience: 'ecm-backend',
                    grant_type: 'client_credentials' 
                  }) 
                });
                const data = await response.json();
                getData(data.access_token)
              } catch (error) {
                console.log(error);
              }  
            }
            getToken()
          } catch (error) {
            setSubmittingForm(false)
            setSubmitError(true)
            console.log(error)
          }
        } else {
            return false;
        }
        setSubmittingForm(false)
    }

    const hoverShowSidebar = (title, desc) => {
        document.getElementById("sidebar-title").innerHTML = `${DOMPurify.sanitize(title)}`
        document.getElementById("sidebar-desc").innerHTML = `${DOMPurify.sanitize(desc)}`
    }
    function hoverOutSidebar () {
        document.getElementById("sidebar-title").innerHTML = ""
        document.getElementById("sidebar-desc").innerHTML = ""
    }
    const KeyCodes = {
      comma: 188,
      enter: 13
    };
      
    const delimiters = [KeyCodes.comma, KeyCodes.enter];
    const [tags, setTags] = useState([]);
    const handleDelete = i => {
      setTags(tags.filter((tag, index) => index !== i));
    };
    const handleAddition = tag => {
      setTags([...tags, tag]);
    };
    const [toast] = useState(0)
    const toaster = useRef()

    return (
        <div id="onboard-div" style={{marginTop: "-20px"}}>
          <CForm onSubmit={handleFormSubmit} autoComplete="off">
            <CContainer>
            <CRow style={{width: "100%"}}>
                <CCol sm={6}>
                    <CFormInput
                      id="fNameInput"
                      autoFocus
                      type="text"
                      label={(
                        <>
                        First name <span style={{color: "var(--jsi-red)"}}><b>*</b></span>
                        <div className='mobile-info'>
                            <CTooltip placement='right' content={`Enter your new employee's first name as it should appear on any user accounts created as well as their contact name in our system.`}>
                                <i style={{color: "#292929", fontSize: "18px"}} className="fa fa-regular fa-circle-question"></i>
                            </CTooltip>
                        </div>
                        </>
                      )}
                      placeholder="First"
                      value={firstName}
                      onChange={(e) => {
                        setFirstName(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1));
                      }}
                      required
                      onFocus={() => (hoverShowSidebar('Employee Name', `Enter your new employee's first and last name as it should appear on any user accounts created as well as their contact name in our system.`))}
                      onBlur={() => hoverOutSidebar()}
                    />
                </CCol>

                <CCol sm={6}>
                    <CFormInput
                      id="lNameInput"
                      style={{fontSize: `${fontSizeContainer}px`}} 
                      type="text"
                      label={(
                        <>
                        Last name <span style={{color: "var(--jsi-red)"}}><b>*</b></span>
                        <div className='mobile-info'>
                            <CTooltip placement='right' content={`Enter ${firstName ? firstName : 'you new employee'}'s last name as it should appear on any user accounts created as well as their contact name in our system.`}>
                                <i style={{color: "#292929", fontSize: "18px"}} className="fa fa-regular fa-circle-question"></i>
                            </CTooltip>
                        </div>
                        </>
                      )}
                      value={lastName}
                      placeholder="Last"
                      onChange={(e) => {
                        setLastName(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1));
                        setDefaultEmail((firstName.charAt(0).toLowerCase()) + (e.target.value.toLowerCase()) + '@' + user.email.split('@')[1])
                      }}
                      required
                      onFocus={() => (hoverShowSidebar('Employee Name', `Enter your new employee's first and last name as it should appear on any user accounts created as well as their contact name in our system.`))}
                      onBlur={() => hoverOutSidebar()}
                    />
                </CCol>
            </CRow>

            <CRow style={{width: "100%"}}>
                <CCol sm={6}>
                    <CFormInput
                        style={{fontSize: `${fontSizeContainer}px`}} 
                      type="email"
                      label={(
                        <>
                        Email
                        <div className='mobile-info'>
                            <CTooltip placement='right' content={`Define an email address for ${firstName ? firstName : 'your new employee'}. We will create the mailbox and save it in our ticketing system so messages sent to help@justinc.com from ${firstName ? firstName : 'your new employee'} will be correctly logged.`}>
                                <i style={{color: "#292929", fontSize: "18px"}} className="fa fa-regular fa-circle-question"></i>
                            </CTooltip>
                        </div>
                        </>
                      )}
                      value={email}
                      defaultValue={defaultEmail}
                      placeholder="Email Address"
                      onChange={(e) => {
                        setEmail(e.target.value)
                      }}
                      onFocus={() => ((email === undefined || email === '') && setEmail(defaultEmail)) & (hoverShowSidebar('Email Address',`Define an email address for ${firstName ? firstName : 'your new employee'}. We will create the mailbox and save it in our ticketing system so messages sent to help@justinc.com from ${firstName ? firstName : 'your new employee'} will be correctly logged.`))}
                      onBlur={() => hoverOutSidebar()}
                      onClick={() => ((email === undefined || email === '') && setEmail(defaultEmail))}
                    />
                </CCol>
                <CCol sm={6}>
                  <CFormSelect 
                    style={{fontSize: `${fontSizeContainer}px`}} 
                    className="license-select"
                    id = "licenseSelect"
                    label={(
                      <>
                      License
                      <div className='mobile-info'>
                          <CTooltip placement='right' content={`Assign a license to ${email ? "the " + email : "your new employee's"} mailbox. If you aren't sure, please reach out to your account manager/vCIO for information about the available options.`}>
                              <i style={{color: "#292929", fontSize: "18px"}} className="fa fa-regular fa-circle-question"></i>
                          </CTooltip>
                      </div>
                      </>
                    )}
                    aria-label="Microsoft 365 License"
                    value={license}
                    options={[
                      { label: '', value: 'Not Selected', color: 'grey'},
                      { label: 'Exchange Online (Plan 1)', value: 'Exchange Online License (Plan 1)' },
                      { label: 'Exchange Online (Plan 2)', value: 'Exchange Online License (Plan 2)' },
                      { label: '365 Business Standard', value: '365 Business Standard' },
                      { label: '365 E3', value: '365 E3 License' },
                      { label: '365 E5', value: '365 E5 License' },
                    ]}
                    onChange={(e) => {
                      setLicense(e.target.value);
                      e.target.value === 'Not Selected' && document.getElementById('licenseSelect').style.setProperty('color', 'grey', 'important')
                    }}
                    onFocus={() => (hoverShowSidebar('Mailbox License',`Assign a license to ${email ? "the " + email : "your new employee's"} mailbox. If you aren't sure, please reach out to your account manager/vCIO for information about the available options.`))}
                    onBlur={() => hoverOutSidebar()}                    
                  />
                </CCol>
            </CRow>
            <CRow style={{width: "100%"}}>
                <CCol sm={4}>
                    <CFormInputWithMask
                    style={{fontSize: `${fontSizeContainer}px`}} 
                      mask="000-000-0000  x0000"
                      label={(
                        <>
                        Phone number
                        <div className='mobile-info'>
                            <CTooltip placement='right' content={`Provide the best number to reach ${firstName ? firstName : 'your new employee'} for this or any other future tickets.`}>
                                <i style={{color: "#292929", fontSize: "18px"}} className="fa fa-regular fa-circle-question"></i>
                            </CTooltip>
                        </div>
                        </>
                      )}
                      value={phone}
                      placeholder="Work or cell number"
                      onChange={(e) => {
                        setPhone(e.target.value);
                      }}
                      onFocus={() => (hoverShowSidebar('Phone Number', `Provide the best number to reach ${firstName ? firstName : 'your new employee'} for this or any other future tickets.`))}
                      onBlur={() => hoverOutSidebar()}
                    />
                </CCol>
                <CCol sm={4}>
                  <Popover
                      positions={['bottom']}
                      content={
                        <DayPicker
                          mode="single"
                          selectedDate={selectedDate}
                          onSelect={setSelectedDate}
                          footer={footer}
                          showOutsideDays
                          fixedWeeks
                        />
                      }
                      isOpen={datePickerVisible}
                      onClickOutside={() => setDatePickerVisible(false)}
                      padding={15}
                  >
                        <CFormInput
                          id="startDateInput"
                          style={{fontSize: `${fontSizeContainer}px`}} 
                          onClick={() => setDatePickerVisible(true)}
                          type="text"
                          label={(
                            <>
                            Start date <span style={{color: "var(--jsi-red)"}}><b>*</b></span>
                            <div className='mobile-info'>
                                <CTooltip placement='right' content={`Enter ${firstName ? firstName : 'your new employee'}'s start date so we can ensure everything setup and ready for their first day.`}>
                                    <i style={{color: "#292929", fontSize: "18px"}} className="fa fa-regular fa-circle-question"></i>
                                </CTooltip>
                            </div>
                            </>
                          )}
                          placeholder="Pick a date"
                          value={(selectedDate && format(selectedDate, 'PPP'))}
                          required
                          onFocus={() => (setDatePickerVisible(true) & hoverShowSidebar('Start Date', `Enter ${firstName ? firstName : 'your new employee'}'s start date so we can ensure everything setup and ready for their first day.`))}
                          onBlur={() => (hoverOutSidebar())}
                        />
                  </Popover>
                </CCol>
                <CCol sm={4}>
                    <CFormInput
                        style={{fontSize: `${fontSizeContainer}px`}} 
                      type="text"
                      label={(
                        <>
                        Supervisor
                        <div className='mobile-info'>
                            <CTooltip placement='right' content={`Define ${firstName ? firstName : 'your new employee'}'s manager or supervisor so we can save it in our system for reference when necessary.`}>
                                <i style={{color: "#292929", fontSize: "18px"}} className="fa fa-regular fa-circle-question"></i>
                            </CTooltip>
                        </div>
                        </>
                      )}
                      placeholder="Manager Name"
                      value={manager}
                      onChange={(e) => {
                        setManager(e.target.value.replace(/\b[a-z]/g, (char) => char.toUpperCase()));
                      }}
                      onFocus={() => (hoverShowSidebar('Manager/Supervisor', `Define ${firstName ? firstName : 'your new employee'}'s manager or supervisor so we can save it in our system for reference when necessary.`))}
                      onBlur={() => hoverOutSidebar()}
                    />
                </CCol>
            </CRow>
            <CRow style={{width: "100%"}}>
                <CCol sm={6}>
                    <CFormInput
                        style={{fontSize: `${fontSizeContainer}px`}} 
                      type="text"
                      label={(
                        <>
                        Computer
                        <div className='mobile-info'>
                            <CTooltip placement='right' content={`If applicable, what workstation or laptop should we get ${firstName ? firstName : 'your new employee'} setup on?`}>
                                <i style={{color: "#292929", fontSize: "18px"}} className="fa fa-regular fa-circle-question"></i>
                            </CTooltip>
                        </div>
                        </>
                      )}
                      placeholder="Machine Name"
                      value={machineName}
                      onChange={(e) => {
                        setMachineName(e.target.value.toUpperCase());
                      }}
                      onFocus={() => (hoverShowSidebar('Assigned Computer', `If applicable, what workstation or laptop should we get ${firstName ? firstName : 'your new employee'} setup on?`))}
                      onBlur={() => hoverOutSidebar()}
                    />
                </CCol>
                <CCol sm={6}>
                    <CFormInput
                        style={{fontSize: `${fontSizeContainer}px`}} 
                      type="text"
                      label={(
                        <>
                        Windows Account
                        <div className='mobile-info'>
                            <CTooltip placement='right' content={`Leave this blank and we'll give ${firstName ? firstName : 'your new employee'} a username that matches the company standard convention.`}>
                                <i style={{color: "#292929", fontSize: "18px"}} className="fa fa-regular fa-circle-question"></i>
                            </CTooltip>
                        </div>
                        </>
                      )}
                      value={username}
                      placeholder="Username (optional)"
                      onChange={(e) => {
                        setUsername(e.target.value);
                      }}
                      onFocus={() => (hoverShowSidebar('Windows Username',`Leave this blank and we'll give ${firstName ? firstName : 'your new employee'} a username that matches the company standard convention.`))}
                      onBlur={() => hoverOutSidebar()}
                    />
                </CCol>
            </CRow>
            <CRow>
            <CAccordion alwaysOpen activeItemKey={3}>
                <CAccordionItem itemKey={2}>
                    <CAccordionHeader>Permissions</CAccordionHeader>
                    <CAccordionBody>
                    <CCol sm={12}>
                    <div style={{paddingBottom: "10px"}}>
                        <span className="accordion-body-header" style={{color: "white"}}>What permissions should we grant for {firstName ? firstName : 'your new employee'}?</span>
                    </div>
                        <CRow>
                        <CCol sm={6}>
                            <CFormInput
                                style={{fontSize: `${fontSizeContainer}px`}} 
                              type="text"
                              label={(
                                <>
                                Mirror the permissions of
                                <div className='mobile-info'>
                                    <CTooltip placement='right' content={`Provide ${firstName ? firstName : 'your new employee'} the same permissions of a specified employee.`}>
                                        <i style={{color: "#292929", fontSize: "18px"}} className="fa fa-regular fa-circle-question"></i>
                                    </CTooltip>
                                </div>
                                </>
                              )}
                              value={userPermissions}
                              placeholder="Employee to mirror (optional)"
                              onChange={(e) => {
                                setUserPermissions(e.target.value.replace(/\b[a-z]/g, (char) => char.toUpperCase()));
                              }}
                              onFocus={() => (hoverShowSidebar('Mirror permissions',`Provide ${firstName ? firstName : 'your new employee'} the same permissions of a specified employee.`))}
                              onBlur={() => hoverOutSidebar()}
                            />
                            <div style={{paddingTop: "12px"}}>
                            <CFormInput
                              style={{fontSize: `${fontSizeContainer}px`}} 
                              type="text"
                              label={(
                                <>
                                except for
                                <div className='mobile-info'>
                                    <CTooltip placement='right' content={`Provide any permissions that we should not mirror over to ${firstName ? firstName : 'your new employee'}.`}>
                                        <i style={{color: "#292929", fontSize: "18px"}} className="fa fa-regular fa-circle-question"></i>
                                    </CTooltip>
                                </div>
                                </>
                              )}
                              value={userPermissionsExceptFor}
                              placeholder="Permissions to NOT mirror (optional)"
                              onChange={(e) => {
                                setUserPermissionsExceptFor(e.target.value);
                              }}
                              onFocus={() => (hoverShowSidebar('Except for these permissions',`Provide any permissions that we should not mirror over to ${firstName ? firstName : 'your new employee'}.`))}
                              onBlur={() => hoverOutSidebar()}
                            />
                            </div>
                        </CCol>
                        <CCol sm={6}>
                            <CFormTextarea
                              style={{fontSize: `${fontSizeContainer}px`}} 
                              label={(
                                <>
                                Other permissions requirements
                                <div className='mobile-info'>
                                    <CTooltip placement='right' content={`Provide some additional information regarding permissions or access that needs to be granted for ${firstName ? firstName : 'your new employee'} or that we should be aware of.`}>
                                        <i style={{color: "#292929", fontSize: "18px"}} className="fa fa-regular fa-circle-question"></i>
                                    </CTooltip>
                                </div>
                                </>
                              )}
                              rows={3}
                              value={permissionsComment}
                              text="Any additional permissions, access, etc."
                              onChange={(e) => {
                                setPermissionsComment(e.target.value);
                              }}
                              onFocus={() => (hoverShowSidebar('Additional Permissions',`Provide some additional information regarding permissions or access that needs to be granted for ${firstName ? firstName : 'your new employee'} or that we should be aware of.`))}
                              onBlur={() => hoverOutSidebar()}
                            ></CFormTextarea>
                        </CCol>
                        </CRow>
                        </CCol>
                    </CAccordionBody>
                </CAccordionItem>
                <CAccordionItem itemKey={1}>
                    <CAccordionHeader>Applications</CAccordionHeader>
                    <CAccordionBody>
                        <CCol sm={12}>
                            <div style={{paddingBottom: "20px"}}>
                                <span className="accordion-body-header" style={{color: "white"}}>List the applications that {firstName ? firstName : 'your new employee'} will need installed.</span>
                                <div className='mobile-info'>
                                    <CTooltip placement='right' content={`Depending on your selections, we may need to reach out regarding licensing, etc.`}>
                                        <i style={{color: "#292929", fontSize: "18px"}} className="fa fa-regular fa-circle-question"></i>
                                    </CTooltip>
                                </div>
                            </div>
                            <div  
                                onFocus={() => (hoverShowSidebar('Applications',`List the applications that ${firstName ? firstName : 'your new employee'} will need installed.`))}
                                onBlur={() => hoverOutSidebar()}
                            >
                            <span className='tags-input-label' style={{color: "#5a5a5a", fontSize: "14px"}}>Hit enter or use a comma after each app or use suggestions.</span>
                            <ReactTags
                                tags={tags}
                                suggestions={suggestions}
                                delimiters={delimiters}
                                handleDelete={handleDelete}
                                handleAddition={handleAddition}
                                inputFieldPosition="top"
                                autocomplete
                                className="react-tags"
                                placeholder="Type application names here."
                            />
                            </div>
                        </CCol>
                    </CAccordionBody>
                </CAccordionItem>
            </CAccordion>
            </CRow>
            <CRow style={{width: "100%"}}>
                <CCol sm={12}>
                    <CFormTextarea
                      label={(
                        <>
                        Other comments or specific needs
                        <div className='mobile-info'>
                            <CTooltip placement='right' content={`Provide some additional information such as hardware - like monitors and/or docking stations, any required or important information you think we should know. Do not enter passwords here.`}>
                                <i style={{color: "#292929", fontSize: "18px"}} className="fa fa-regular fa-circle-question"></i>
                            </CTooltip>
                        </div>
                        </>
                      )}
                      rows={3}
                      value={comment}
                      onChange={(e) => {
                        setComment(e.target.value);
                      }}
                      onFocus={() => (hoverShowSidebar('Additional Information',`Provide some additional information such as hardware - like monitors and/or docking stations, any required or important information you think we should know. Do not enter passwords here.`))}
                      onBlur={() => hoverOutSidebar()}
                    ></CFormTextarea>
                </CCol>
            </CRow>
            <CRow style={{width: "100%"}}>
                <CCol sm={12}>
                    <>
                    <CToaster ref={toaster} push={toast} autohide={false} visible={true} placement="top-end" />
                      <CButton className='review-btn' style={{borderRadius: "5px"}} onClick={() => checkRequiredFields()}>Review & Submit</CButton>
                      <div id='review-modal'>
                      <CModal scrollable alignment="center" visible={visible} onClose={() => setVisible(false)}>
                        {submittedSuccess ?
                        <>
                            <CModalHeader onClose={() => setVisible(false)}>
                              <CModalTitle style={{fontSize: "20px"}}>Success</CModalTitle>
                            </CModalHeader>
                            <div class="success-animation">
                                <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" /><path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
                                <br/>
                                <div style={{textAlign: "center"}}>
                                  <h2 className='submitted-ticket-msg'>Ticket {resultTicket} has been submitted.</h2>
                                </div>
                                <div style={{textAlign: "center"}}>
                                    <span style={{fontSize: "20px"}}>You'll receive a confirmation email shortly!</span>
                                </div>
                            </div>
                        </>
                        :
                        submitError ? 
                        <>
                            <CModalHeader onClose={() => setVisible(false)}>
                              <CModalTitle style={{fontSize: "20px"}}>Error</CModalTitle>
                            </CModalHeader>
                            <div class="success-animation">
                              <svg class="xmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle class="xmark__circle" cx="26" cy="26" r="25" fill="none" /><path class="xmark__check" fill="none" d="M16 16 36 36 M36 16 16 36" /></svg>
                              <br/>
                              <center>
                                <h2 className='submitted-ticket-msg'>An error has occurred.</h2>
                              </center>
                              <div style={{textAlign: "center", paddingLeft: "8px", paddingRight: "8px"}}>
                                  <span style={{fontSize: "20px"}}>Please refresh the page and try again. If the issue persists, please reach out to us at 585-321-9999 or help@justinc.com.</span>
                                  {/* <br/>
                                  <br/>
                                  <CButton onClick={() => window.location.reload()}>Submit a ticket from here</CButton> */}
                              </div>
                            </div>
                        </>
                        :
                        <>
                        <CModalHeader onClose={() => setVisible(false)}>
                          <CModalTitle style={{fontSize: "20px"}}>Review</CModalTitle>
                        </CModalHeader>
                        <CModalBody>
                        <CTable>
                            <CTableHead>
                                <CTableRow>
                                    <CTableHeaderCell scope="col">Data</CTableHeaderCell>
                                    <CTableHeaderCell scope="col">Value</CTableHeaderCell>
                                </CTableRow>
                            </CTableHead>
                            <CTableBody>
                                <CTableRow>
                                    <CTableHeaderCell scope="row">First Name</CTableHeaderCell>
                                    <CTableDataCell>{firstNameEdit ? 
                                        <span>
                                            <CFormInput autoFocus className='modalForm' value={firstName} onBlur={() => setFirstNameEdit(false)} onChange={(e) => { 
                                                setFirstName(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)) 
                                            }} /> <i onClick={() => setFirstNameEdit(false)} style={{paddingLeft: "7px", fontSize: "18px", cursor: "pointer"}} className="fa-solid fa-check"></i>
                                        </span>
                                        : 
                                        <span>
                                            {firstName} <i onClick={() => setFirstNameEdit(true)} style={{paddingLeft: "7px", cursor: "pointer"}} className="fa-solid fa-pencil"></i>
                                        </span>
                                    }</CTableDataCell>
                                </CTableRow>
                                <CTableRow>
                                    <CTableHeaderCell scope="row">Last Name</CTableHeaderCell>
                                    <CTableDataCell>{lastNameEdit ? 
                                        <span>
                                            <CFormInput autoFocus className='modalForm' value={lastName} onBlur={() => setLastNameEdit(false)} onChange={(e) => { setLastName(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)) }} /> <i onClick={() => setLastNameEdit(false)} style={{paddingLeft: "7px", fontSize: "18px", cursor: "pointer"}} className="fa-solid fa-check"></i>
                                        </span>
                                        : 
                                        <span>
                                            {lastName} <i onClick={() => setLastNameEdit(true)} style={{paddingLeft: "7px", cursor: "pointer"}} className="fa-solid fa-pencil"></i>
                                        </span>
                                    }</CTableDataCell>
                                </CTableRow>
                                <CTableRow>
                                    <CTableHeaderCell scope="row">Email</CTableHeaderCell>
                                    <CTableDataCell>{emailEdit ? 
                                        <span>
                                            <CFormInput autoFocus className='modalForm' value={email} onBlur={() => setEmailEdit(false)} onChange={(e) => { setEmail(e.target.value) }} /> <i onClick={() => setEmailEdit(false)} style={{paddingLeft: "7px", fontSize: "18px", cursor: "pointer"}} className="fa-solid fa-check"></i>
                                        </span>
                                        : 
                                        <span>
                                            {email} <i onClick={() => setEmailEdit(true)} style={{paddingLeft: "7px", cursor: "pointer"}} className="fa-solid fa-pencil"></i>
                                        </span>
                                    }</CTableDataCell>
                                </CTableRow>
                                <CTableRow>
                                    <CTableHeaderCell scope="row">License</CTableHeaderCell>
                                    <CTableDataCell>{licenseEdit ? 
                                        <span style={{display: "inline-flex"}}>
                                            <CFormSelect 
                                              className='modalSelect'
                                              value={license}
                                              options={[
                                                'Select a license',
                                                { label: 'Exchange Online License', value: 'Exchange Online License' },
                                                { label: '365 Business Standard', value: '365 Business Standard' },
                                                { label: '365 E3 License', value: '365 E3 License' },
                                                { label: '365 E5 License', value: '365 E5 License' },
                                              ]}
                                              onChange={(e) => {
                                                setLicense(e.target.value);
                                              }}
                                              onBlur={() => setLicenseEdit(false)}
                                            /> <i onClick={() => setLicenseEdit(false)} style={{paddingLeft: "10px", paddingTop: "8px", fontSize: "18px", cursor: "pointer"}} className="fa-solid fa-check"></i>
                                        </span>
                                        : 
                                        <span>
                                            {license} <i onClick={() => setLicenseEdit(true)} style={{paddingLeft: "7px", cursor: "pointer"}} className="fa-solid fa-pencil"></i>
                                        </span>
                                    }</CTableDataCell>
                                </CTableRow>
                                <CTableRow>
                                    <CTableHeaderCell scope="row">Phone Number</CTableHeaderCell>
                                    <CTableDataCell>{phoneEdit ? 
                                        <span>
                                            <CFormInputWithMask autoFocus mask="000-000-0000  x0000" className='modalForm' value={phone} onBlur={() => setPhoneEdit(false)} onChange={(e) => { setPhone(e.target.value) }} /> <i onClick={() => setPhoneEdit(false)} style={{paddingLeft: "7px", fontSize: "18px", cursor: "pointer"}} className="fa-solid fa-check"></i>
                                        </span>
                                        : 
                                        <span>
                                            {phone} <i onClick={() => setPhoneEdit(true)} style={{paddingLeft: "7px", cursor: "pointer"}} className="fa-solid fa-pencil"></i>
                                        </span>
                                    }</CTableDataCell>
                                </CTableRow>
                                <CTableRow>
                                    <CTableHeaderCell scope="row">Assigned Computer</CTableHeaderCell>
                                    <CTableDataCell>{machineNameEdit ? 
                                        <span>
                                            <CFormInput autoFocus className='modalForm' value={machineName} onBlur={() => setMachineNameEdit(false)} onChange={(e) => { setMachineName(e.target.value.toUpperCase()) }} /> <i onClick={() => setMachineNameEdit(false)} style={{paddingLeft: "7px", fontSize: "18px", cursor: "pointer"}} className="fa-solid fa-check"></i>
                                        </span>
                                        : 
                                        <span>
                                            {machineName} <i onClick={() => setMachineNameEdit(true)} style={{paddingLeft: "7px", cursor: "pointer"}} className="fa-solid fa-pencil"></i>
                                        </span>
                                    }</CTableDataCell>
                                </CTableRow>
                                <CTableRow>
                                    <CTableHeaderCell scope="row">Username</CTableHeaderCell>
                                    <CTableDataCell>{usernameEdit ? 
                                        <span>
                                            <CFormInput autoFocus className='modalForm' value={username} onBlur={() => setUsernameEdit(false)} onChange={(e) => { setUsername(e.target.value) }} /> <i onClick={() => setUsernameEdit(false)} style={{paddingLeft: "7px", fontSize: "18px", cursor: "pointer"}} className="fa-solid fa-check"></i>
                                        </span>
                                        : 
                                        <span>
                                            {username} <i onClick={() => setUsernameEdit(true)} style={{paddingLeft: "7px", cursor: "pointer"}} className="fa-solid fa-pencil"></i>
                                        </span>
                                    }</CTableDataCell>
                                </CTableRow>

                                <CTableRow>
                                    <CTableHeaderCell scope="row">Start Date</CTableHeaderCell>
                                    <CTableDataCell>{startDateEdit ? 
                                      <CContainer fluid>
                                        <Popover
                                          positions={['top', 'right', 'left', 'bottom']}
                                          content={
                                            <DayPicker
                                              mode="single"
                                              selectedDate={selectedDate}
                                              onSelect={setSelectedDate}
                                              footer={footer}
                                              showOutsideDays
                                              fixedWeeks
                                              style={{zIndex: "999999999999"}}
                                            />
                                          }
                                          isOpen={editDatePickerVisible}
                                          onClickOutside={() => setEditDatePickerVisible(false)}
                                          padding={15}
                                          style={{zIndex: "999999999999"}}
                                        >
                                        <CFormInput
                                            autoFocus
                                            className='modalForm'
                                            onClick={() => setEditDatePickerVisible(true)}
                                            value={(selectedDate && format(selectedDate, 'PPP'))}
                                            onChange={(e) => {
                                                setSelectedDate(e.target.value);
                                            }}
                                            onFocus={() => (setEditDatePickerVisible(true))}
                                            onBlur={() => setStartDateEdit(false)}
                                        />
                                        </Popover> 
                                        <i onClick={() => setStartDateEdit(false)} style={{paddingLeft: "7px", fontSize: "18px", cursor: "pointer"}} className="fa-solid fa-check"></i>
                                      </CContainer>
                                      : 
                                      <span>
                                          {selectedDate && format(selectedDate, 'PPP')} <i onClick={() => setStartDateEdit(true)} style={{paddingLeft: "7px", cursor: "pointer"}} className="fa-solid fa-pencil"></i>
                                      </span>
                                    }</CTableDataCell>
                                </CTableRow>
                                <CTableRow>
                                    <CTableHeaderCell scope="row">Manager/Supervisor</CTableHeaderCell>
                                    <CTableDataCell>{managerEdit ? 
                                        <span>
                                            <CFormInput autoFocus className='modalForm' value={manager} onBlur={() => setManagerEdit(false)} onChange={(e) => { setManager(e.target.value.replace(/\b[a-z]/g, (char) => char.toUpperCase())) }} /> <i onClick={() => setManagerEdit(false)} style={{paddingLeft: "7px", fontSize: "18px", cursor: "pointer"}} className="fa-solid fa-check"></i>
                                        </span>
                                        : 
                                        <span>
                                            {manager} <i onClick={() => setManagerEdit(true)} style={{paddingLeft: "7px", cursor: "pointer"}} className="fa-solid fa-pencil"></i>
                                        </span>
                                    }</CTableDataCell>
                                </CTableRow>
                                <CTableRow>
                                    <CTableHeaderCell scope="row">Mirror Permissions of</CTableHeaderCell>
                                    <CTableDataCell>{permissionsMirrorEdit ? 
                                        <span>
                                            <CFormInput autoFocus className='modalForm' value={userPermissions} onBlur={() => setPermissionsMirrorEdit(false)} onChange={(e) => { setUserPermissions(e.target.value.replace(/\b[a-z]/g, (char) => char.toUpperCase())) }} /> <i onClick={() => setPermissionsMirrorEdit(false)} style={{paddingLeft: "7px", fontSize: "18px", cursor: "pointer"}} className="fa-solid fa-check"></i>
                                        </span>
                                        : 
                                        <span>
                                            {userPermissions} <i onClick={() => setPermissionsMirrorEdit(true)} style={{paddingLeft: "7px", cursor: "pointer"}} className="fa-solid fa-pencil"></i>
                                        </span>
                                    }</CTableDataCell>
                                </CTableRow>
                                <CTableRow>
                                    <CTableHeaderCell scope="row">Except for these permissions</CTableHeaderCell>
                                    <CTableDataCell>{permissionsExceptEdit ? 
                                        <span>
                                            <CFormInput autoFocus className='modalForm' value={userPermissionsExceptFor} onBlur={() => setPermissionsExceptEdit(false)} onChange={(e) => { setUserPermissionsExceptFor(e.target.value) }} /> <i onClick={() => setPermissionsExceptEdit(false)} style={{paddingLeft: "7px", fontSize: "18px", cursor: "pointer"}} className="fa-solid fa-check"></i>
                                        </span>
                                        : 
                                        <span>
                                            {userPermissionsExceptFor} <i onClick={() => setPermissionsExceptEdit(true)} style={{paddingLeft: "7px", cursor: "pointer"}} className="fa-solid fa-pencil"></i>
                                        </span>
                                    }</CTableDataCell>
                                </CTableRow>
                                <CTableRow>
                                    <CTableHeaderCell scope="row">Permissions Comment</CTableHeaderCell>
                                    <CTableDataCell>{permissionsCommentEdit ? 
                                        <span>
                                            <CFormTextarea autoFocus className='modalForm' value={permissionsComment} onBlur={() => setPermissionsCommentEdit(false)} onChange={(e) => { setPermissionsComment(e.target.value) }} /> <i onClick={() => setPermissionsCommentEdit(false)} style={{paddingLeft: "7px", fontSize: "18px", cursor: "pointer"}} className="fa-solid fa-check"></i>
                                        </span>
                                        : 
                                        <span>
                                            {permissionsComment} <i onClick={() => setPermissionsCommentEdit(true)} style={{paddingLeft: "7px", cursor: "pointer"}} className="fa-solid fa-pencil"></i>
                                        </span>
                                    }</CTableDataCell>
                                </CTableRow>
                                <CTableRow>
                                    <CTableHeaderCell scope="row">Applications</CTableHeaderCell>
                                    <CTableDataCell>{false ? 
                                        <span>
                                            <CListGroup>
                                                {tags.map(value => (
                                                    // <CListGroupItem>{value.text}</CListGroupItem>
                                                    <div style={{paddingTop: "2px", paddingBottom: "2px"}}>
                                                        <CFormInput autoFocus className='modalForm' value={value.text} />
                                                    </div>
                                                ))}
                                            </CListGroup>
                                        </span>
                                        : 
                                        <span>
                                            <CListGroup>
                                                {tags.map(value => (
                                                    <CListGroupItem>{value.text}</CListGroupItem>
                                                ))}
                                            </CListGroup>
                                        </span>
                                    }</CTableDataCell>
                                </CTableRow>
                                <CTableRow>
                                    <CTableHeaderCell scope="row">Comment</CTableHeaderCell>
                                    <CTableDataCell>{commentEdit ? 
                                        <span>
                                            <CFormTextarea autoFocus rows={3} className='modalForm' value={comment} onBlur={() => setCommentEdit(false)} onChange={(e) => { setComment(e.target.value) }} /> <i onClick={() => setCommentEdit(false)} style={{paddingLeft: "7px", fontSize: "18px", cursor: "pointer"}} className="fa-solid fa-check"></i>
                                        </span>
                                        : 
                                        <span>
                                            {comment} <i onClick={() => setCommentEdit(true)} style={{paddingLeft: "7px", cursor: "pointer"}} className="fa-solid fa-pencil"></i>
                                        </span>
                                    }</CTableDataCell>
                                </CTableRow>
                            </CTableBody>
                            </CTable>
                        </CModalBody>
                        </>
                        }
                        <CModalFooter>
                          {success ?
                          <CButton color="secondary" onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
                            Logout
                          </CButton>
                          :
                          <CButton color="secondary" onClick={() => setVisible(false)}>
                            Go Back
                          </CButton>
                          }
                          
                          {submittingForm ? <CButton><span style={{paddingRight: "7px"}}><span className="btn-loader"></span></span> Submitting</CButton> : 
                            success ? <><CButton color='secondary' onClick={() => window.location.reload()}>Refresh Page</CButton><CButton disabled><i style={{paddingRight: "7px"}} className="fa fa-solid fa-check"></i> Submitted</CButton></>
                            : submitError ? <><CButton onClick={() => window.location.reload()}>Refresh Page</CButton><CButton onClick={handleFormSubmit}>Try again</CButton></> : <CButton onClick={handleFormSubmit}>Submit</CButton>}
                        </CModalFooter>
                      </CModal>
                      </div>
                    </>
                </CCol>
            </CRow>
            </CContainer>
            </CForm>
            <ToastContainer />
        </div>
    )
}