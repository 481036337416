import React from 'react';
import '../App.css';
import '../style.css';

export function Header({subheading, heading}) {
    return (
        <div id='onboarding-div' className='onboarding-div'>
            <div className='onboarding-div-inner'>
				<span className='onboarding-header-sm'>{subheading}</span>
				<br/>
				<span className="pt-md-4 onboarding-header-lg">{heading}</span>
			</div>
        </div>
    )
}