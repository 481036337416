import React, { useEffect } from 'react';
import FadeIn from "./FadeIn";
import logo from './jsilogo.png';
import logoWhite from './jsilogo-white.png';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocalStorage } from './components/useLocalStorage';
import { CCard, CCardBody, CCardTitle, CCardText, CButton, CRow, CCol } from '@coreui/react';

export default function Login() {
  const { isAuthenticated, error, loginWithRedirect } = useAuth0();

  const [theme] = useLocalStorage("theme","light-mode");
  useEffect(() => {
    document.body.className = theme;
    document.getElementsByTagName('input').className = theme;
    document.getElementsByClassName('form-select').className = theme;
  }, [theme]);

  // const [fontSizeButton] = useLocalStorage("font-size-btn",16)
  // var t = document.querySelector(':root');
  // t.style.setProperty('--font-size-button', `${fontSizeButton}px`);

  const [fontSizeLabel] = useLocalStorage("font-size-label",18)
  var r = document.querySelector(':root');
  r.style.setProperty('--font-size-labels', `${fontSizeLabel}px`);

  const [fontSizeContainer] = useLocalStorage("font-size-container",20)
  console.log(fontSizeContainer);
  var l = document.querySelector(':root');
  l.style.setProperty('--font-size-containers', `${fontSizeContainer}px`);

  if (isAuthenticated) {
    window.open('/','_self')
  }

  if (error) {
    return <div style={{paddingTop: "50px", marginLeft: "50%"}}>A login error occurred. If this continues, please submit a ticket referencing this error:  {error.message}</div>;
  } else {
    return (
      <body>
        <FadeIn duration={400} delay={70}>
          <div className='center'>
            <CCard className="login-card" style={{ maxWidth: '22rem', height: "max-content" }}>
                <div style={{padding: "10px", justifyContent: "center", display: "inline-flex"}}>
                    <img alt='JSI-logo' orientation="top" src={theme === 'light-mode' ? logo : logoWhite} />
                </div>
                <CCardBody>
                  <CCardTitle>Client ECM</CCardTitle>
                  <CCardText style={{fontSize: "18px"}}>
                    Login to JSI Client ECM to access the management portal.
                  </CCardText>
                  <CRow>
                      <CCol sm={6}>
                          <div>
                              <CButton style={{float: "left"}} onClick={() => loginWithRedirect()}>Login</CButton>
                          </div>
                      </CCol>
                      <CCol sm={6}>
                          <div id="dev-wrap">
                              {/* <CCardText style={{float: "right", marginBottom: "-20px", paddingTop: "18px"}}>
                                  dev-b25
                              </CCardText> */}
                              {/* <CButton style={{float: "right"}} onClick={() => loginWithRedirect()}>Go to JSI</CButton> */}
                          </div>
                      </CCol>
                  </CRow>
                </CCardBody>
              </CCard>
            </div>
        </FadeIn>
      </body>
    )
  }
};