import React from 'react';
import '../App.css';
import '../style.css';
import { DescriptionArea } from './DescriptionArea';
import { LogoArea } from './LogoArea';

export function LeftSidebar() {
    return (
        <div className='row-side'>
            <LogoArea />
            <DescriptionArea />
        </div>
    )
}