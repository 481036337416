import { withAuthenticationRequired } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { useLocalStorage } from "./components/useLocalStorage";

export const AuthenticationGuard = ({ component }) => {
  const [theme] = useLocalStorage("theme","light-mode");
  useEffect((fontSizeContainer) => {
      function setFont(fontSize) {
        var r = document.querySelector(':root');
        r.style.setProperty('--font-size-container', fontSize);
      }
    setFont(fontSizeContainer)
    document.body.className = theme;
    document.getElementsByTagName('input').className = theme;
    document.getElementsByClassName('form-select').className = theme;
  }, [theme]);

  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <>
        <div className='center-no-bg'>
          <div>
            <span className="loader"><span style={{color: "black"}}>.</span></span>
          </div>
        </div>
      </>
    ),
  });

  return <Component />;
};