import React from 'react';
import './App.css';
import './style.css';
// import 'font-awesome/css/font-awesome.min.css';
import '@fortawesome/fontawesome-free/css/all.css';

import ProgressBar from 'react-progressbar-on-scroll';
import { useAuth0 } from '@auth0/auth0-react';

import { LeftSidebar } from './components/LeftSidebar';
import { MainContent } from './components/MainContent';

export default function OnboardContentLand() {
  const { error } = useAuth0();

  const refreshPage = () => {     
    const protocol = window.location.hostname.includes('localhost') ? 'http' : 'https';
    window.open(`${protocol}://${window.location.port ? `${window.location.hostname}:${window.location.port}` : window.location.hostname}`,"_self");
  }
  let interval = setInterval(refreshPage, 607000);
  const resetInterval = () => { 
      clearInterval(interval);
      interval = setInterval(refreshPage, 607000);
  }

  if (error) {
    return <div>A login error occurred. If this continues, please submit a ticket referencing this error:  {error.message}</div>;
  } else {
    return (
      <div onMouseMove={() => resetInterval()} style={{width: "100%"}}>
      <div onMouseMove={() => resetInterval()} className='App'>
        <section className="ftco-section">
        <ProgressBar style={{zIndex: "999999999"}} color='#EB1B2C' />
          <div id='sidebar-id' className='row-side'>
            <LeftSidebar />
          </div>
          <div id='main-contain' className="container">
            <div id='mobile-sidebar'><LeftSidebar /></div>
            <MainContent />
          </div>
        </section>
      </div>
      </div>
    );
  };
} 
