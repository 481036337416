import React, { useState, useRef, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import '../App.css';
import '../style.css';
import '../styles.css';
import 'react-select-search/style.css'
import '@coreui/coreui/dist/css/coreui.min.css';
import 'react-day-picker/dist/style.css';
import { useLocalStorage } from './useLocalStorage';
import { IMaskMixin } from 'react-imask'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DOMPurify from 'dompurify';
import { 
  CForm, 
  CFormInput, 
  CCol, 
  CContainer, 
  CRow, 
  CFormTextarea, 
  CButton, 
  CModal, 
  CModalHeader, 
  CModalTitle, 
  CModalBody, 
  CModalFooter, 
  CTooltip, 
  CToaster
} from '@coreui/react';

const notify = (str) => toast.error(<span style={{fontSize: "17px"}}>{str}</span>, {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  closeButton: false,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
});

const { REACT_APP_AUTH0_DOMAIN } = process.env;
const { REACT_APP_API_CLIENT_ID } = process.env;
const { REACT_APP_API_CLIENT } = process.env;
const { REACT_APP_API_HOST } = process.env;

const CFormInputWithMask = IMaskMixin(({ inputRef, ...props }) => (
    <CFormInput
      {...props}
      ref={inputRef}
    />
  ))

export function GeneralTicketForm () {
    document.title = "Submit a ticket - JSI"

    const { user, logout } = useAuth0();

    const [subjectName, setSubjectName] = useState();
    const [lastName, setLastName] = useState();
    const [email, setEmail] = useState();
    const [license, setLicense] = useState();
    const [manager, setManager] = useState();
    const [machineName, setMachineName] = useState();
    const [username, setUsername] = useState();
    const [phone, setPhone] = useState();
    const [comment, setComment] = useState();
    const [userPermissions, setUserPermissions] = useState();
    const [userPermissionsExceptFor, setUserPermissionsExceptFor] = useState();
    const [permissionsComment, setPermissionsComment] = useState();
    const [permissionsCommentEdit, setPermissionsCommentEdit] = useState();
    const [permissionsMirrorEdit, setPermissionsMirrorEdit] = useState();
    const [permissionsExceptEdit, setPermissionsExceptEdit] = useState();

    const [datePickerVisible, setDatePickerVisible] = useState();
    const [editDatePickerVisible, setEditDatePickerVisible] = useState();
    const [visible, setVisible] = useState(false)

    const [subjectEdit, setSubjectEdit] = useState();
    const [lastNameEdit, setLastNameEdit] = useState();
    const [emailEdit, setEmailEdit] = useState();
    const [licenseEdit, setLicenseEdit] = useState();
    const [phoneEdit, setPhoneEdit] = useState();
    const [managerEdit, setManagerEdit] = useState();
    const [machineNameEdit, setMachineNameEdit] = useState();
    const [usernameEdit, setUsernameEdit] = useState();
    const [commentEdit, setCommentEdit] = useState();
    const [startDateEdit, setStartDateEdit] = useState();

    const [defaultEmail, setDefaultEmail] = useState();

    const [submittingForm, setSubmittingForm] = useState(false);
    const [success, setSuccess] = useState();
    const [submitError, setSubmitError] = useState(false);
    const [submittedSuccess, setSubmittedSuccess] = useState(false);
    const [resultTicket, setResultTicket] = useState();
    const [fontSizeLabel] = useLocalStorage("font-size-label");
    const [fontSizeContainer] = useLocalStorage("font-size-container");

    useEffect(() => {
      console.log(fontSizeContainer + ' onboardingform');
      var r = document.querySelector(':root');
      r.style.setProperty('--font-size-container', `${fontSizeContainer}px`);
    },[fontSizeContainer])

    useEffect(() => {
        function setFontLabels() {
            var l = document.querySelector(':root');
            l.style.setProperty('--font-size-labels', `${fontSizeLabel}px`);
        }
        setFontLabels()
    }, [fontSizeLabel])

    // const [selectedDate, setSelectedDate] = useState();
    // let footer = <p>Choose a start date</p>;
    // if (selectedDate) {
    //   footer = <p>{(subjectName ? `${subjectName} will start on ${format(selectedDate, 'PPP')}` : `Starting on ${format(selectedDate, 'PPP')}`)}</p>;
    // }

    const submitForm = () => {
      setSubmittingForm(true)
    }

    const checkRequiredFields = () => {
      if (subjectName === "" || subjectName === undefined) {
        notify("Subject is required")
        setVisible(false)
        document.getElementById("onboarding-div").scrollIntoView()
        document.getElementById("subjectNameInput").focus()
        return false;
      } else if (comment === "" || comment === undefined) {
        notify("Ticket description is required")
        setVisible(false)
        document.getElementById("onboarding-div").scrollIntoView()
        document.getElementById("ticketDescription").focus()
        return false;
      } else {
        setVisible(!visible)
        return true;
      }
    }

    const handleFormSubmit = (e) => {
        e.preventDefault()
        submitForm()
        setSubmittingForm(true)
        setSubmitError(false)
        let arr = tags.map(value => value.text)
        if (subjectName !== "" && lastName !== "") {
          try {
            async function getData(accessToken) {
              setSubmittingForm(true)
              const response = await fetch(REACT_APP_API_HOST + '/api/v1/submitonb', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  authorization: `Bearer ${accessToken}`
                },
                body: JSON.stringify({
                  requestType: `Onboarding`,
                  submittedByName: `${user.name}`,
                  submittedByEmail: `${user.email}`,
                  subjectName: `${subjectName}`,
                  lastName: `${lastName}`,
                  email: `${email}`,
                  license: `${license === undefined ? '*BLANK*' : license}`,
                //   startDate: `${format(selectedDate, 'PPP')}`,
                  phone: `${phone === undefined ? '*BLANK*' : phone}`,
                  manager: `${manager === undefined ? '*BLANK*' : manager}`,
                  machineName: `${machineName === undefined ? '*BLANK*' : machineName}`,
                  applications: `${JSON.stringify(arr)}`,
                  userPermissions: `${userPermissions === undefined ? '*BLANK*' : userPermissions}`,
                  userPermissionsExceptFor: `${userPermissionsExceptFor === undefined ? '*BLANK*' : userPermissionsExceptFor}`,
                  permissionsComment: `${permissionsComment === undefined ? '*BLANK*' : permissionsComment}`,
                  comment: `${comment === undefined ? '*BLANK*' : comment}`
                })
              });
              try {
                const data = await response.json();

                data.ticketId && setResultTicket(data.ticketId)
                data.ticketId ? setSuccess(true) : setSuccess(false)
                data.ticketId ? setSubmittedSuccess(true) : setSubmittedSuccess(false)
                
                setSubmittingForm(false)
              } catch {
                setSubmittingForm(false)
                setSubmitError(true)
              }
            }
            async function getToken() {
              setSubmittingForm(true)
              try {
                setSubmittingForm(true)
                const response = await fetch(`${REACT_APP_AUTH0_DOMAIN}/oauth/token`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({
                    client_id: REACT_APP_API_CLIENT_ID,
                    client_secret: REACT_APP_API_CLIENT,
                    audience: 'ecm-backend',
                    grant_type: 'client_credentials' 
                  }) 
                });
                const data = await response.json();
                getData(data.access_token)
              } catch (error) {
                console.log(error); 
              }  
            }
            getToken()
          } catch (error) {
            setSubmittingForm(false)
            setSubmitError(true)
            console.log(error)
          }
        } else {
            return false;
        }
        setSubmittingForm(false)
    }

    const hoverShowSidebar = (title, desc) => {
        document.getElementById("sidebar-title").innerHTML = `${DOMPurify.sanitize(title)}`
        document.getElementById("sidebar-desc").innerHTML = `${DOMPurify.sanitize(desc)}`
    }
    function hoverOutSidebar () {
        document.getElementById("sidebar-title").innerHTML = ""
        document.getElementById("sidebar-desc").innerHTML = ""
    }
    const KeyCodes = {
      comma: 188,
      enter: 13
    };
      
    const delimiters = [KeyCodes.comma, KeyCodes.enter];
    const [tags, setTags] = useState([]);
    const handleDelete = i => {
      setTags(tags.filter((tag, index) => index !== i));
    };
    const handleAddition = tag => {
      setTags([...tags, tag]);
    };
    const [toast] = useState(0)
    const toaster = useRef()

    return (
        <div id="onboard-div" style={{marginTop: "-10px"}}>
          <CForm onSubmit={handleFormSubmit} autoComplete="off">
            <CContainer>
            <CRow style={{width: "100%"}}>
                <CCol sm={6}>
                    <CFormInputWithMask
                    style={{fontSize: `${fontSizeContainer}px`}} 
                      mask="000-000-0000  x0000"
                      label={(
                        <>
                        Phone number
                        <div className='mobile-info'>
                            <CTooltip placement='right' content={`Provide the best number to reach you regarding this ticket.`}>
                                <i style={{color: "#292929", fontSize: "18px"}} className="fa fa-regular fa-circle-question"></i>
                            </CTooltip>
                        </div>
                        </>
                      )}
                      value={phone}
                      placeholder="Phone number"
                      onChange={(e) => {
                        setPhone(e.target.value);
                      }}
                      onFocus={() => (hoverShowSidebar('Phone Number', `Provide the best number to reach you regarding this ticket.`))}
                      onBlur={() => hoverOutSidebar()}
                    />
                </CCol>
                <CCol sm={6}>
                    <CFormInput
                        style={{fontSize: `${fontSizeContainer}px`}} 
                      type="text"
                      label={(
                        <>
                        Computer
                        <div className='mobile-info'>
                            <CTooltip placement='right' content={`If applicable, what workstation or laptop is affected by this issue?`}>
                                <i style={{color: "#292929", fontSize: "18px"}} className="fa fa-regular fa-circle-question"></i>
                            </CTooltip>
                        </div>
                        </>
                      )}
                      placeholder="Machine Name"
                      value={machineName}
                      onChange={(e) => {
                        setMachineName(e.target.value.toUpperCase());
                      }}
                      onFocus={() => (hoverShowSidebar('Assigned Computer', `If applicable, what workstation or laptop is affected by this issue?`))}
                      onBlur={() => hoverOutSidebar()}
                    />
                </CCol>
            </CRow>
            <CRow style={{width: "100%"}}>
                <CCol sm={12}>
                    <CFormInput
                      id="subjectNameInput"
                      autoFocus
                      type="text"
                      label={(
                        <>
                        Subject *
                        <div className='mobile-info'>
                            <CTooltip placement='right' content={`In a few words, enter a brief summary of the issue.`}>
                                <i style={{color: "#292929", fontSize: "18px"}} className="fa fa-regular fa-circle-question"></i>
                            </CTooltip>
                        </div>
                        </>
                      )}
                      placeholder="Brief summary of the issue"
                      value={subjectName}
                      onChange={(e) => {
                        setSubjectName(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1));
                      }}
                      required
                      onFocus={() => (hoverShowSidebar('Ticket Subject', `In a few words, enter a brief summary of the issue.`))}
                      onBlur={() => hoverOutSidebar()}
                    />
                </CCol>
            </CRow>
            <CRow style={{width: "100%"}}>
                <CCol sm={12}>
                    <CFormTextarea
                      id="ticketDescription"
                      label={(
                        <>
                        Ticket description *
                        <div className='mobile-info'>
                            <CTooltip placement='right' content={`Provide some additional information such as permissions, additional hardware - like monitors and docking stations, any required software, etc.`}>
                                <i style={{color: "#292929", fontSize: "18px"}} className="fa fa-regular fa-circle-question"></i>
                            </CTooltip>
                        </div>
                        </>
                      )}
                      rows={3}
                      value={comment}
                      text="Describe in detail what problems are being experienced"
                      onChange={(e) => {
                        setComment(e.target.value);
                      }}
                      onFocus={() => (hoverShowSidebar('Additional Information',`Provide some additional information such as permissions, additional hardware - like monitors and docking stations, any required software, etc.`))}
                      onBlur={() => hoverOutSidebar()}
                    ></CFormTextarea>
                </CCol>
            </CRow>
            <CRow style={{width: "100%"}}>
                <CCol sm={12}>
                    <>
                    <CToaster ref={toaster} push={toast} autohide={false} visible={true} placement="top-end" />
                      <CButton className='review-btn' style={{borderRadius: "5px"}} onClick={() => checkRequiredFields()}>Review & Submit</CButton>
                      <div id='review-modal'>
                      <CModal scrollable alignment="center" visible={visible} onClose={() => setVisible(false)}>
                        {submittedSuccess ?
                        <>
                            <CModalHeader onClose={() => setVisible(false)}>
                              <CModalTitle style={{fontSize: "20px"}}>Success</CModalTitle>
                            </CModalHeader>
                            <div class="success-animation">
                                <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" /><path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
                                <br/>
                                <div style={{textAlign: "center"}}>
                                  <h2 className='submitted-ticket-msg'>Ticket {resultTicket} has been submitted.</h2>
                                </div>
                                <div style={{textAlign: "center"}}>
                                    <span style={{fontSize: "20px"}}>You'll receive a confirmation email shortly!</span>
                                </div>
                            </div>
                        </>
                        :
                        submitError ? 
                        <>
                            <CModalHeader onClose={() => setVisible(false)}>
                              <CModalTitle style={{fontSize: "20px"}}>Error</CModalTitle>
                            </CModalHeader>
                            <div class="success-animation">
                              <svg class="xmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle class="xmark__circle" cx="26" cy="26" r="25" fill="none" /><path class="xmark__check" fill="none" d="M16 16 36 36 M36 16 16 36" /></svg>
                              <br/>
                              <center>
                                <h2 className='submitted-ticket-msg'>An error has occurred.</h2>
                              </center>
                              <div style={{textAlign: "center", paddingLeft: "8px", paddingRight: "8px"}}>
                                  <span style={{fontSize: "20px"}}>Please refresh the page and try again. If the issue persists, please reach out to us at 585-321-9999 or help@justinc.com.</span>
                                  {/* <br/>
                                  <br/>
                                  <CButton onClick={() => window.location.reload()}>Submit a ticket from here</CButton> */}
                              </div>
                            </div>
                        </>
                        :
                        <>
                        <CModalHeader onClose={() => setVisible(false)}>
                          <CModalTitle style={{fontSize: "20px"}}>Review</CModalTitle>
                        </CModalHeader>
                        <CModalBody>
                            <CRow style={{width: "100%"}}>
                                <CCol sm={12}>
                                    {subjectEdit ? 
                                        <span>
                                            <CFormInput autoFocus className='modalForm' value={subjectName} onBlur={() => setSubjectEdit(false)} onChange={(e) => { 
                                                setSubjectName(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)) 
                                            }} /> <i onClick={() => setSubjectEdit(false)} style={{paddingLeft: "7px", fontSize: "18px", cursor: "pointer"}} className="fa-solid fa-check"></i>
                                        </span>
                                        : 
                                        <span>
                                            {subjectName} <i onClick={() => setSubjectEdit(true)} style={{paddingLeft: "7px", cursor: "pointer"}} className="fa-solid fa-pencil"></i>
                                        </span>
                                    }
                                </CCol>
                            </CRow>
                            <br/>
                            <CRow style={{width: "100%"}}>
                                <CCol sm={12}>
                                    {commentEdit ? 
                                        <span>
                                            <CFormTextarea autoFocus rows={3} className='modalForm' value={comment} onBlur={() => setCommentEdit(false)} onChange={(e) => { setComment(e.target.value) }} /> <i onClick={() => setCommentEdit(false)} style={{paddingLeft: "7px", fontSize: "18px", cursor: "pointer"}} className="fa-solid fa-check"></i>
                                        </span>
                                        : 
                                        <span>
                                            {comment} <i onClick={() => setCommentEdit(true)} style={{paddingLeft: "7px", cursor: "pointer"}} className="fa-solid fa-pencil"></i>
                                        </span>
                                    }
                                </CCol>
                            </CRow>
                        </CModalBody>
                        </>
                        }
                        <CModalFooter>
                          {success ?
                          <CButton color="secondary" onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
                            Logout
                          </CButton>
                          :
                          <CButton color="secondary" onClick={() => setVisible(false)}>
                            Go Back
                          </CButton>
                          }
                          
                          {submittingForm ? <CButton><span style={{paddingRight: "7px"}}><span className="btn-loader"></span></span> Submitting</CButton> : 
                            success ? <><CButton color='secondary' onClick={() => window.location.reload()}>Refresh Page</CButton><CButton disabled><i style={{paddingRight: "7px"}} className="fa fa-solid fa-check"></i> Submitted</CButton></>
                            : submitError ? <><CButton onClick={() => window.location.reload()}>Refresh Page</CButton><CButton onClick={handleFormSubmit}>Try again</CButton></> : <CButton onClick={handleFormSubmit}>Submit</CButton>}
                        </CModalFooter>
                      </CModal>
                      </div>
                    </>
                </CCol>
            </CRow>
            </CContainer>
            </CForm>
            <ToastContainer />
        </div>
    )
}