import React from 'react';
import '../App.css';
import '../style.css';
import { Header } from './Header';
import { OnboardingForm } from './OnboardingForm';

export function Onboarding() {
    return (
        <div>
            <Header
              subheading={"New Employee"}
              heading={"Onboarding"}
            />
            <OnboardingForm />
        </div>
    )
}