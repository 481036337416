import React from 'react';
import { Routes, Route } from "react-router-dom";
import './App.css';
import './style.css';
import 'font-awesome/css/font-awesome.min.css';
// import '@fortawesome/fontawesome-free/css/all.css'

import OnboardingContentLand from './OnboardContentLand';
import Login from './Login';
import OffboardContent from './OffboardContent';
import { AuthenticationGuard } from './authentication-guard';
import GeneralTicketContent from './GeneralTicketContent';
import PrinterSuppliesContent from './PrinterSupplies';

function App() {
  return (
    <Routes>
      <Route exact path="/" element={<Login />} />
      <Route exact path="/onboard" element={<AuthenticationGuard component={OnboardingContentLand} />} />
      <Route exact path="/offboard" element={<AuthenticationGuard component={OffboardContent} />} />
      <Route exact path="/ticket" element={<AuthenticationGuard component={GeneralTicketContent} />} />
      <Route exact path="/printer-supplies" element={<AuthenticationGuard component={PrinterSuppliesContent} />} />
    </Routes>
  )
}

export default App;
