import React from 'react';
import '../App.css';
import '../style.css';

export function DescriptionArea() {
    return (
        <div className="row-side">
            <div className="descriptionAreaDiv">
                <p id="sidebar-title"></p>
                <p id="sidebar-desc"></p>
            </div>
        </div>
    )
}