import React, { useState, useEffect } from 'react';
import logo from '../jsilogo.png';
import whiteLogo from '../jsilogo-white.png';
import '../App.css';
import '../style.css';
import { useLocalStorage } from "./useLocalStorage";
import { useAuth0 } from '@auth0/auth0-react';
import { DarkModeSwitch } from 'react-toggle-dark-mode';
import { 
    CRow, 
    CButtonGroup, 
    CButton, 
    CDropdown, 
    CDropdownItem, 
    CDropdownMenu, 
    CDropdownToggle, 
    CContainer, 
    CModal, 
    CModalHeader, 
    CModalTitle, 
    CModalBody, 
    CCallout,
    CCol, 
    CFormText 
} from '@coreui/react';

export function LogoArea() {
    const { logout } = useAuth0();
    const [visible, setVisible] = useState();

    const [checked, setChecked] = useLocalStorage("themeCheck", false)
    const [theme, setTheme] = useLocalStorage("theme","light-mode");

    const imageToPreload = theme === 'light-mode' ? whiteLogo : logo;

    const [fontSizeLabel, setFontSizeLabel] = useLocalStorage("font-size-label");
    var r = document.querySelector(':root');
    r.style.setProperty('--font-size-labels', `${fontSizeLabel}px`);

    const [fontSizeContainer, setFontSizeContainer] = useLocalStorage("font-size-container");

    const increaseFont = () => {
        setFontSizeContainer(fontSizeContainer + 1);
        setFontSizeLabel(fontSizeLabel + 1);
        var r = document.querySelector(':root');
        r.style.setProperty('--font-size-containers', `${fontSizeContainer + 1}px`);
        r.style.setProperty('--font-size-labels', `${fontSizeLabel + 1}px`);
    }
    const decreaseFont = () => {
        setFontSizeContainer(fontSizeContainer - 1);
        setFontSizeLabel(fontSizeLabel - 1);
        var r = document.querySelector(':root');
        r.style.setProperty('--font-size-containers', `${fontSizeContainer - 1}px`);
        r.style.setProperty('--font-size-labels', `${fontSizeLabel - 1}px`);
    }

    const toggleTheme = () => {
      if (theme === 'light-mode') {
        setChecked(true);
        setTheme('dark-mode');
      } else {
        setChecked(false);
        setTheme('light-mode');
      }
    };

    useEffect(() => {
        document.body.className = theme;
        document.getElementsByClassName('form-select').className = theme;
    }, [theme]);

    useEffect(() => {
        const img = new Image();
        img.src = imageToPreload;
      }, [imageToPreload]);

    return (
        <div className="row-side zindexhigh">
            <div>
                <div className="logoAreaWrapper">
                    <div className="logoArea">
                        <img style={{cursor: "pointer"}} onClick={() => window.open('https://justinc.com','_blank')} src={checked ? whiteLogo : logo} height={"75px"} width={"291px"} alt="Just Solutions, Inc."/>
                        <h2 className="login-heading">Client ECM</h2>
                        <>
                            <CContainer fluid>
                                <CRow>
                                    <CCol sm={6}>
                                    <div className='font-size-div' style={{display: "flex", justifyContent: "center", paddingTop: "3px"}}>
                                        <DarkModeSwitch
                                            // style={{ marginBottom: '2rem' }}
                                            checked={!checked} 
                                            onChange={toggleTheme}
                                            size={30}
                                            sunColor={"white"}
                                            moonColor={"black"}
                                        />
                                        <div style={{marginTop: "-3px", cursor: "pointer"}} onClick={toggleTheme}>
                                            <CFormText className='noselect' style={{paddingLeft: "8px"}}>
                                                <div className='font-size-div'>
                                                    {!checked ? 'Dark Mode' : 'Light Mode'}
                                                </div>
                                            </CFormText>
                                        </div>
                                    </div>
                                    </CCol>
                                    <CCol sm={6} style={{paddingBottom: "10px", marginBottom: "0px"}}>
                                        <CFormText className='noselect'>
                                            <div className='font-size-div'>
                                                <span style={{fontSize: "16px", paddingRight: "7px", cursor: "pointer"}}>
                                                    <i onClick={decreaseFont} className="fa fa-solid fa-circle-minus font-plus-minus"></i>
                                                </span>
                                                Font Size
                                                <span style={{fontSize: "16px", paddingLeft: "7px", cursor: "pointer"}}>
                                                    <i onClick={increaseFont} className="fa fa-solid fa-circle-plus font-plus-minus"></i>
                                                </span>
                                            </div>
                                        </CFormText>
                                    </CCol>
                                </CRow>
                                <CButtonGroup role="group">
                                    <CButton onClick={() => window.open('/onboard','_self')}><i style={{paddingLeft: "7px", fontSize: "24px"}} className="fa fa-solid fa-user-plus"></i> Onboarding</CButton>
                                    <CDropdown variant="btn-group">
                                        <CDropdownToggle><i style={{paddingLeft: "7px", fontSize: "24px", display: "flex", justifyContent: "center"}} className="fa fa-solid fa-user-xmark"></i> Offboarding</CDropdownToggle>
                                        <CDropdownMenu style={{}}>
                                            <CDropdownItem onClick={() => setVisible(!visible)}><i style={{paddingRight: "6.2px", marginLeft: "-1.2px"}} className="fa-solid fa-triangle-exclamation"></i> Urgent Termination</CDropdownItem>
                                            <CDropdownItem href="/offboard"><i style={{paddingRight: "7px"}} className="fa-solid fa-calendar-days"></i> Future Termination</CDropdownItem>
                                        </CDropdownMenu>
                                    </CDropdown>
                                    <CButton onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })} color="primary"><i style={{fontSize: "24px", display: "flex", justifyContent: "center"}} className="fa fa-solid fa-right-from-bracket"></i> Logout</CButton>
                                </CButtonGroup>
                                <CModal alignment="center" visible={visible} onClose={() => setVisible(false)}>
                                    <CModalHeader onClose={() => setVisible(false)}>
                                      <CModalTitle style={{fontSize: "15px"}}><i style={{paddingRight: "12px"}} className="fa-solid fa-triangle-exclamation"></i>Urgent or Risky</CModalTitle>
                                    </CModalHeader>
                                    <CModalBody>
                                        <div style={{paddingBottom: "10px", marginTop: "-25px", textAlign: "center"}}>
                                            <CCallout style={{backgroundColor: "#161718", color: "#EB1B2C", fontSize: "18px"}} color="danger">
                                                Please reach out to us by phone for urgent or risky terminations.
                                                <br/>
                                                <span style={{fontSize: "34px"}} onClick={() => window.open('tel:+15853219999','_self')}>585-321-9999</span>
                                                <br/>
                                                <span className='mobile-phone-call' style={{fontSize: "24px"}} onClick={() => window.open('tel:+15853219999','_self')}><i style={{fontSize: "20px", paddingRight: "5px"}} className="fa fa-solid fa-phone mobile-phone-call"></i> Call Now</span>
                                            </CCallout>
                                        </div>
                                        <p>Please call us any time an employee termination is urgent or you feel it is a high risk. Please see only a few scenarios below that could be considered urgent or risky.</p>
                                        <p>A termination can be considered urgent or risky if the terminated employee has:</p>
                                        <ul>
                                            <li>already been terminated and we have not yet been made aware</li>
                                            <li>been deemed or was presenting disgruntled before or after termination notice</li>
                                            <li>had access to sensitive information or data that could be used for malicious purposes</li>
                                            <li>held a highly privileged position with access to very sensitive data</li>
                                        </ul>
                                    </CModalBody>
                                </CModal>
                            </CContainer>
                        </>
                    </div>
                </div>
            </div>
        </div>
    )
}
